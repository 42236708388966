/* ************************************************************************************************ */
/* Import Fonts *********************************************************************************** */
/* ************************************************************************************************ */

/* Lato: Hairline (weight 100) */
/*
@font-face {
	font-family: 'Lato';
	src:  url('../fonts/lato/lato-hairline.eot?v=1.0.0');
	src:  url('../fonts/lato/lato-hairline.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-hairline.woff2?v=1.0.0') format('woff2'),
          url('../fonts/lato/lato-hairline.woff?v=1.0.0') format('woff'),
          url('../fonts/lato/lato-hairline.ttf?v=1.0.0') format('truetype');
	font-weight: 100;
	font-style: normal;
}
*/

/* Lato: Hairline Italic (weight 100 style italic) */
/*
@font-face {
	font-family: 'Lato', Arial, Helvetica, sans-serif;
	src:  url('../fonts/lato/lato-hairlineitalic.eot?v=1.0.0');
	src:  url('../fonts/lato/lato-hairlineitalic.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-hairlineitalic.woff2?v=1.0.0') format('woff2'),
          url('../fonts/lato/lato-hairlineitalic.woff?v=1.0.0') format('woff'),
          url('../fonts/lato/lato-hairlineitalic.ttf?v=1.0.0') format('truetype');
	font-weight: 100;
	font-style: italic;
}
*/

/* Lato: Thin (weight 200) */
/*
@font-face {
	font-family: 'Lato';
	src:  url('../fonts/lato/lato-thin.eot?v=1.0.0');
	src:  url('../fonts/lato/lato-thin.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-thin.woff2?v=1.0.0') format('woff2'),
          url('../fonts/lato/lato-thin.woff?v=1.0.0') format('woff'),
          url('../fonts/lato/lato-thin.ttf?v=1.0.0') format('truetype');
	font-weight: 200;
	font-style: normal;
}
*/

/* Lato: Thin Italic (weight 200 style italic) */
/*
@font-face {
	font-family: 'Lato';
	src:  url('../fonts/lato/lato-thinitalic.eot?v=1.0.0');
	src:  url('../fonts/lato/lato-thinitalic.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-thinitalic.woff2?v=1.0.0') format('woff2'),
          url('../fonts/lato/lato-thinitalic.woff?v=1.0.0') format('woff'),
          url('../fonts/lato/lato-thinitalic.ttf?v=1.0.0') format('truetype');
	font-weight: 200;
	font-style: italic;
}
*/

/* Lato: Light (weight 300) */
/*
@font-face {
	font-family: 'Lato';
	src:  url('../fonts/lato/lato-light.eot?v=1.0.0');
	src:  url('../fonts/lato/lato-light.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-light.woff2?v=1.0.0') format('woff2'),
          url('../fonts/lato/lato-light.woff?v=1.0.0') format('woff'),
          url('../fonts/lato/lato-light.ttf?v=1.0.0') format('truetype');
	font-weight: 300;
	font-style: normal;
}*/

/* Lato: Light Italic (weight 300 style italic) */
/*
@font-face {
	font-family: 'Lato';
	src:  url('../fonts/lato/lato-lightitalic.eot?v=1.0.0');
	src:  url('../fonts/lato/lato-lightitalic.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-lightitalic.woff2?v=1.0.0') format('woff2'),
          url('../fonts/lato/lato-lightitalic.woff?v=1.0.0') format('woff'),
          url('../fonts/lato/lato-lightitalic.ttf?v=1.0.0') format('truetype');
	font-weight: 300;
	font-style: italic;
}
*/

/* Lato: Regular (weight normal) */
@font-face {
	font-family: 'Lato';
	src:  url('../fonts/lato/lato-regular.eot?v=1.0.0');
	src:  url('../fonts/lato/lato-regular.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-regular.woff2?v=1.0.0') format('woff2'),
          url('../fonts/lato/lato-regular.woff?v=1.0.0') format('woff'),
          url('../fonts/lato/lato-regular.ttf?v=1.0.0') format('truetype');
	font-weight: normal;
	font-style: normal;
}

/* Lato: Regular (weight 400) */
@font-face {
	font-family: 'Lato';
	src:  url('../fonts/lato/lato-regular.eot?v=1.0.0');
	src:  url('../fonts/lato/lato-regular.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-regular.woff2?v=1.0.0') format('woff2'),
          url('../fonts/lato/lato-regular.woff?v=1.0.0') format('woff'),
          url('../fonts/lato/lato-regular.ttf?v=1.0.0') format('truetype');
	font-weight: 400;
	font-style: normal;
}

/* Lato: Regular Italic (weight normal style italic) */
@font-face {
	font-family: 'Lato';
	src:  url('../fonts/lato/lato-italic.eot?v=1.0.0');
	src:  url('../fonts/lato/lato-italic.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-italic.woff2?v=1.0.0') format('woff2'),
          url('../fonts/lato/lato-italic.woff?v=1.0.0') format('woff'),
          url('../fonts/lato/lato-italic.ttf?v=1.0.0') format('truetype');
	font-weight: normal;
	font-style: italic;
}

/* Lato: Regular Italic (weight 400 style italic) */
@font-face {
	font-family: 'Lato';
	src:  url('../fonts/lato/lato-italic.eot?v=1.0.0');
	src:  url('../fonts/lato/lato-italic.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-italic.woff2?v=1.0.0') format('woff2'),
          url('../fonts/lato/lato-italic.woff?v=1.0.0') format('woff'),
          url('../fonts/lato/lato-italic.ttf?v=1.0.0') format('truetype');
	font-weight: 400;
	font-style: italic;
}

/* Lato: Medium (weight 500) */
@font-face {
	font-family: 'Lato';
	src:  url('../fonts/lato/lato-medium.eot?v=1.0.0');
	src:  url('../fonts/lato/lato-medium.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-medium.woff2?v=1.0.0') format('woff2'),
          url('../fonts/lato/lato-medium.woff?v=1.0.0') format('woff'),
          url('../fonts/lato/lato-medium.ttf?v=1.0.0') format('truetype');
	font-weight: 500;
	font-style: normal;
}

/* Lato: Medium Italic (weight 500 style italic) */
@font-face {
	font-family: 'Lato';
	src:  url('../fonts/lato/lato-mediumitalic.eot?v=1.0.0');
	src:  url('../fonts/lato/lato-mediumitalic.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-mediumitalic.woff2?v=1.0.0') format('woff2'),
          url('../fonts/lato/lato-mediumitalic.woff?v=1.0.0') format('woff'),
          url('../fonts/lato/lato-mediumitalic.ttf?v=1.0.0') format('truetype');
	font-weight: 500;
	font-style: italic;
}

/* Lato: Semibold (weight 600) */
/*
@font-face {
	font-family: 'Lato';
	src:  url('../fonts/lato/lato-semibold.eot?v=1.0.0');
	src:  url('../fonts/lato/lato-semibold.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-semibold.woff2?v=1.0.0') format('woff2'),
          url('../fonts/lato/lato-semibold.woff?v=1.0.0') format('woff'),
          url('../fonts/lato/lato-semibold.ttf?v=1.0.0') format('truetype');
	font-weight: 600;
	font-style: normal;
}
*/

/* Lato: Semibold Italic (weight 600 style italic) */
/*
@font-face {
	font-family: 'Lato';
	src:  url('../fonts/lato/lato-semibolditalic.eot?v=1.0.0');
	src:  url('../fonts/lato/lato-semibolditalic.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-semibolditalic.woff2?v=1.0.0') format('woff2'),
          url('../fonts/lato/lato-semibolditalic.woff?v=1.0.0') format('woff'),
          url('../fonts/lato/lato-semibolditalic.ttf?v=1.0.0') format('truetype');
	font-weight: 600;
	font-style: italic;
}
*/

/* Lato: Bold (weight 700) */
@font-face {
	font-family: 'Lato';
	src:  url('../fonts/lato/lato-bold.eot?v=1.0.0');
	src:  url('../fonts/lato/lato-bold.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-bold.woff2?v=1.0.0') format('woff2'),
          url('../fonts/lato/lato-bold.woff?v=1.0.0') format('woff'),
          url('../fonts/lato/lato-bold.ttf?v=1.0.0') format('truetype');
	font-weight: 700;
	font-style: normal;
}

/* Lato: Bold Italic (weight 700 style italic) */
@font-face {
	font-family: 'Lato';
	src:  url('../fonts/lato/lato-bolditalic.eot?v=1.0.0');
	src:  url('../fonts/lato/lato-bolditalic.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-bolditalic.woff2?v=1.0.0') format('woff2'),
          url('../fonts/lato/lato-bolditalic.woff?v=1.0.0') format('woff'),
          url('../fonts/lato/lato-bolditalic.ttf?v=1.0.0') format('truetype');
	font-weight: 700;
	font-style: italic;
}

/* Lato: Heavy (weight 800) */
/*
@font-face {
	font-family: 'Lato';
	src:  url('../fonts/lato/lato-heavy.eot?v=1.0.0');
	src:  url('../fonts/lato/lato-heavy.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-heavy.woff2?v=1.0.0') format('woff2'),
          url('../fonts/lato/lato-heavy.woff?v=1.0.0') format('woff'),
          url('../fonts/lato/lato-heavy.ttf?v=1.0.0') format('truetype');
	font-weight: 800;
	font-style: normal;
}
*/

/* Lato: Heavy Italic (weight 800 style italic) */
/*
@font-face {
	font-family: 'Lato';
	src:  url('../fonts/lato/lato-heavyitalic.eot?v=1.0.0');
	src:  url('../fonts/lato/lato-heavyitalic.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-heavyitalic.woff2?v=1.0.0') format('woff2'),
          url('../fonts/lato/lato-heavyitalic.woff?v=1.0.0') format('woff'),
          url('../fonts/lato/lato-heavyitalic.ttf?v=1.0.0') format('truetype');
	font-weight: 800;
	font-style: italic;
}
*/

/* Lato: Black (weight 900) */
/*
@font-face {
	font-family: 'Lato';
	src:  url('../fonts/lato/lato-black.eot?v=1.0.0');
	src:  url('../fonts/lato/lato-black.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-black.woff2?v=1.0.0') format('woff2'),
          url('../fonts/lato/lato-black.woff?v=1.0.0') format('woff'),
          url('../fonts/lato/lato-black.ttf?v=1.0.0') format('truetype');
	font-weight: 900;
	font-style: normal;
}
*/

/* Lato: Black Italic (weight 900 style italic) */
/*
@font-face {
	font-family: 'Lato';
	src:  url('../fonts/lato/lato-blackitalic.eot?v=1.0.0');
	src:  url('../fonts/lato/lato-blackitalic.eot?#iefix') format('embedded-opentype'),
          url('../fonts/lato/lato-blackitalic.woff2?v=1.0.0') format('woff2'),
          url('../fonts/lato/lato-blackitalic.woff?v=1.0.0') format('woff'),
          url('../fonts/lato/lato-blackitalic.ttf?v=1.0.0') format('truetype');
	font-weight: 900;
	font-style: italic;
}
*/

/* Roboto Slab: Thin (weight 200) */
@font-face {
	font-family: 'RobotoSlab';
	src: url('../fonts/roboto-slab/RobotoSlab-Thin.ttf?v=1.0.0') format('truetype');
	font-weight: 200;
	font-style: normal;
}

/* Roboto Slab: Light (weight 300) */
@font-face {
	font-family: 'RobotoSlab';
	src: url('../fonts/roboto-slab/RobotoSlab-Light.ttf?v=1.0.0') format('truetype');
	font-weight: 300;
	font-style: normal;
}

/* Roboto Slab: Regular (weight normal) */
@font-face {
	font-family: 'RobotoSlab';
	src: url('../fonts/roboto-slab/RobotoSlab-Regular.ttf?v=1.0.0') format('truetype');
	font-weight: normal;
	font-style: normal;
}

/* Roboto Slab: Regular (weight 400) */
@font-face {
	font-family: 'RobotoSlab';
	src: url('../fonts/roboto-slab/RobotoSlab-Regular.ttf?v=1.0.0') format('truetype');
	font-weight: 400;
	font-style: normal;
}

/* Roboto Slab: Medium (weight 500) */
@font-face {
	font-family: 'RobotoSlab';
	src: url('../fonts/roboto-slab/RobotoSlab-Medium.ttf?v=1.0.0') format('truetype');
	font-weight: 500;
	font-style: normal;
}

/* Roboto Slab: Bold (weight 600) */
@font-face {
	font-family: 'RobotoSlab';
	src: url('../fonts/roboto-slab/RobotoSlab-Bold.ttf?v=1.0.0') format('truetype');
	font-weight: 600;
	font-style: normal;
}

/* Font Awesome 5 Brands */
@font-face {
	font-family: 'Font Awesome Brands';
	src:  url('../fonts/fontawesome/fa-brands-400.eot?hu3sw6');
	src:  url('../fonts/fontawesome/fa-brands-400.eot?hu3sw6#iefix') format('embedded-opentype'),
		  url('../fonts/fontawesome/fa-brands-400.ttf?hu3sw6') format('truetype'),
		  url('../fonts/fontawesome/fa-brands-400.woff?hu3sw6') format('woff'),
		  url('../fonts/fontawesome/fa-brands-400.woff2?hu3sw6') format('woff'),
		  url('../fonts/fontawesome/fa-brands-400.svg?hu3sw6#fa') format('svg');
	font-weight: normal;
	font-style: normal;
}

/* Font Awesome 5 (Light) */
@font-face {
	font-family: 'Font Awesome Light';
	src:  url('../fonts/fontawesome/fa-light-300.eot?hu3sw5');
	src:  url('../fonts/fontawesome/fa-light-300.eot?hu3sw5#iefix') format('embedded-opentype'),
		  url('../fonts/fontawesome/fa-light-300.ttf?hu3sw5') format('truetype'),
		  url('../fonts/fontawesome/fa-light-300.woff?hu3sw5') format('woff'),
		  url('../fonts/fontawesome/fa-light-300.woff2?hu3sw5') format('woff'),
		  url('../fonts/fontawesome/fa-light-300.svg?hu3sw5#fa') format('svg');
	font-weight: 300;
	font-style: normal;
}

/* Font Awesome 5 (Regular) */
@font-face {
	font-family: 'Font Awesome Regular';
	src:  url('../fonts/fontawesome/fa-regular-400.eot?hu3sw4');
	src:  url('../fonts/fontawesome/fa-regular-400.eot?hu3sw4#iefix') format('embedded-opentype'),
		  url('../fonts/fontawesome/fa-regular-400.ttf?hu3sw4') format('truetype'),
		  url('../fonts/fontawesome/fa-regular-400.woff?hu3sw4') format('woff'),
		  url('../fonts/fontawesome/fa-regular-400.woff2?hu3sw4') format('woff'),
		  url('../fonts/fontawesome/fa-regular-400.svg?hu3sw4#fa') format('svg');
	font-weight: 400;
	font-style: normal;
}

/* Font Awesome 5 (Solid) */
@font-face {
	font-family: 'Font Awesome Solid';
	src:  url('../fonts/fontawesome/fa-solid-900.eot?hu3sw3');
	src:  url('../fonts/fontawesome/fa-solid-900.eot?hu3sw3#iefix') format('embedded-opentype'),
		  url('../fonts/fontawesome/fa-solid-900.ttf?hu3sw3') format('truetype'),
		  url('../fonts/fontawesome/fa-solid-900.woff?hu3sw3') format('woff'),
		  url('../fonts/fontawesome/fa-solid-900.woff2?hu3sw3') format('woff'),
		  url('../fonts/fontawesome/fa-solid-900.svg?hu3sw3#fa') format('svg');
	font-weight: 900;
	font-style: normal;
}

/* LMI Icons */
@font-face {
	font-family: 'lmi';
	src:  url('../fonts/lmi/lmi.eot?hu3sw2');
	src:  url('../fonts/lmi/lmi.eot?hu3sw2#iefix') format('embedded-opentype'),
		  url('../fonts/lmi/lmi.ttf?hu3sw2') format('truetype'),
		  url('../fonts/lmi/lmi.woff?hu3sw2') format('woff'),
		  url('../fonts/lmi/lmi.svg?hu3sw2#lmi') format('svg');
	font-weight: normal;
	font-style: normal;
}



/* ************************************************************************************************ */
/* System Text Fonts ****************************************************************************** */
/* ************************************************************************************************ */
.font {
    font-family: Arial, Helvetica, sans-serif;
    
    &-arial		{ font-family: Arial, Helvetica, sans-serif; }
    &-lato		{ font-family: 'Lato', Arial, Helvetica, sans-serif; }
    &-robotoslab{ font-family: 'RobotoSlab', Arial, Helvetica, sans-serif; }
}
