.slick-slider {
    position: relative;
    display: block;

    @include boxsizing(border-box);
    @include userselect(none);

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    }

.slick-list {
    position: relative;
    margin: 0;
    padding: 0;
    display: block;
    overflow: hidden;

    &:focus {
        outline: none;
        }
    &.dragging {
        cursor: pointer;
        cursor: hand;
        }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    @include transform(translate3d(0, 0, 0));
    }

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    display: block;

    &::before,
    &::after {
        display: table;
        content: '';
        }

    &::after {
        clear: both;
        }
}

.slick-loading .slick-track {
    visibility: hidden;
    }

.slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
    }

[dir='rtl'] .slick-slide {
    float: right;
    }

.slick-slide {
    img {
        display: block;
        }

    &.slick-loading img {
        display: none;
        }
    &.dragging img {
        pointer-events: none;
        }
}

.slick-initialized .slick-slide {
    display: block;
    }

.slick-loading .slick-slide {
    visibility: hidden;
    }

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
    }

.slick-arrow.slick-hidden {
    display: none;
    }

@import 'slick-theme';
