/* ************************************************************************************************ */
/* Forms ****************************************************************************************** */
/* ************************************************************************************************ */
#section-main {
    
    /* Fields */
    input.text,
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="phone"],
    input[type="tel"],
    input[type="text"],
    select,
    textarea {
        padding: 0 20px;
        display: block;
        width: 100%;
        height: 40px;
        font-family: $font1;
        font-size: 0.95rem;
        border: 1px solid $color-gray-1;
        border-color: rgba($color-gray-1,0.30);

        @include rounded(2px);
        @include transition(
            color 0.10s ease-in-out,
            border-color 0.10s ease-in-out,
            background-color 0.10s ease-in-out
        );

        /* Hover State */
        &:hover {
            border-color: $color-violet-2;
            }

        /* Focus State */
        &:focus {
            border-color: $color-violet-2;
            }
    }

    textarea {
        padding: 15px 20px;
        height: 200px;
        }
}
