/* ************************************************************************************************ */
/* SCSS Functions ********************************************************************************* */
/* ************************************************************************************************ */

/** Slightly Lighten a Color
 * @access public
 * @param {Color} $color - color to tint
 * @param {Number} $percentage - percentage of `$color` in returned color
 * @return {Color}
 * Usage: color: tint(#daba55, 42%);
 */
@function tint($color, $percentage) {
    @return mix(white, $color, $percentage);
}
  
/** Slightly Darken a Color
 * @access public
 * @param {Color} $color - color to shade
 * @param {Number} $percentage - percentage of `$color` in returned color
 * @return {Color}
 * Usage: background-color: shade(#663399, 42%);
 */
@function shade($color, $percentage) {
    @return mix(black, $color, $percentage);
}