/* ************************************************************************************************ */
/* Compliance ************************************************************************************* */
/* ************************************************************************************************ */

/* ************************************************************* */
/* i. 508 Compliance/Helpers *********************************** */
/* ************************************************************* */
.sr-only,
.screen-reader-text {
	position: absolute !important;
	top: -9999em !important;
	left: -9999em !important;
    margin: -1px !important;
    padding: 0 !important;
    display: block !important;
	width: 1px !important;
	height: 1px !important;
    font-size: 1px !important;
    line-height: 1px !important;
    overflow: hidden !important;
    border: 0 none !important;
    outline: 0 none !important;
    clip: rect(0,0,0,0) !important;
	}