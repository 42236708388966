@charset 'UTF-8';

/* Slider */
.slick-loading .slick-list {
    /* background: $color-white url('./ajax-loader.gif') center center no-repeat; */
    }

/* Icons */
/*
@font-face {
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url('./fonts/slick.eot');
    src: url('./fonts/slick.eot?#iefix') format('embedded-opentype'),
         url('./fonts/slick.woff') format('woff'),
         url('./fonts/slick.ttf') format('truetype'),
         url('./fonts/slick.svg#slick') format('svg');
}
*/

/* Arrows */
.slick-prev,
.slick-next {
    position: absolute;
    top: 50%;
    padding: 0;
    display: block;
    width: 20px;
    height: 20px;
    color: transparent;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent;

    @include transform(translate(0, -50%));

    &:hover,
    &:focus {
        color: transparent;
        outline: none;
        background: transparent;

        &::before {
            @include opacity(1.00);
            }
    }

    &.slick-disabled {
        &::before {
            @include opacity(0.25);
            }
    }

    &:before {
        color: $color-white;
        /* font-family: 'slick'; */
        font-size: 20px;
        line-height: 1;

        @include opacity(0.75);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        }
}

.slick-prev {
    left: -25px;
    }
[dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
    }
.slick-prev::before {
    content: '←';
    }
[dir='rtl'] .slick-prev::before {
    content: '→';
    }

.slick-next {
    right: -25px;
    }
[dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
    }
.slick-next::before {
    content: '→';
    }
[dir='rtl'] .slick-next::before {
    content: '←';
    }

/* Dots */
.slick-dotted {
    &.slick-slider {
        margin-bottom: 30px;
        }
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    list-style: none;
    text-align: center;

    li {
        position: relative;
        margin: 0 5px;
        padding: 0;
        display: inline-block;
        width: 20px;
        height: 20px;
        cursor: pointer;

        button {
            padding: 5px;
            display: block;
            width: 20px;
            height: 20px;
            color: transparent;
            font-size: 0;
            line-height: 0;
            cursor: pointer;
            border: 0;
            outline: none;
            background: transparent;

            &:hover,
            &:focus {
                outline: none;

                &::before {
                    @include opacity(1.00);
                    }
            }

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                color: $color-black;
                font-family: 'slick';
                font-size: 6px;
                line-height: 20px;
                text-align: center;
                content: '•';

                @include opacity(0.25);
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                }
        }

        &.slick-active {
            button {
                &::before {
                    color: $color-black;

                    @include opacity(0.75);
                    }
            }
        }
    }
}
