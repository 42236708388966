/* ************************************************************************************************ */
/* Footer ***************************************************************************************** */
/* ************************************************************************************************ */
.section-footer {
	.sec-footer {

		> .container {
            padding-right: 15px;
            padding-left: 15px;
			max-width: $maxwidth;

			> .row {}
		}

        /* News and Updates Row */
        &.news-and-updates {
            padding-top: 1px;
            padding-bottom: 0;
            background-color: $color-darkgray-5;
            }

        /* Copyright and Social Row */
        &.copyright-and-social {
            padding-top: 10px;
            padding-bottom: 10px;
            }

		/* Column Navigation Header */
		h3 {
			position: relative;
			margin: 0 0 12px;
			padding: 0;
			display: inline-block;
			color: $color-darkgray-2;
			font-family: $font2;
			font-size: 0.90rem;
			font-weight: 400;
			text-transform: uppercase;

			&::before {
				position: absolute;
				top: 50%;
				right: -35px;
				margin: -1px 0 0;
				width: 22px;
				height: 2px;
				display: block;
				content: ' ';
				background-color: $color-violet-5;

				@include rounded(1px);
				}
		}

		/* Paragraph Text */
		p {
			font-size: 0.90rem;
			line-height: 1.45rem;

			a {
				color: $color-darkgray-2;

				&:hover {
					color: $color-violet-2;
					}
			}

            &.copyright {
                margin: 0;
                padding: 0;
                display: block;
                }
		}


		ul {

            /* Column Footer Navigation List */
            &.footer-menu {
                display: block;

                > li {
                    position: relative;
                    margin: 0 10px 0 0;
                    padding: 5px 10px 5px 0;
                    display: inline-block;
                    font-size: 0.975rem;
                    font-weight: 300;
                    line-height: 1.25rem;

                    &::before {
                        position: absolute;
                        top: 50%;
                        right: -1px;
                        margin: -6px 0 0;
                        display: block;
                        width: 1px;
                        height: 12px;
                        content: ' ';
                        background-color: $color-darkgray-3;
                        }

                    &:last-child {
                        &::before {
                            display: none;
                            }
                    }

                    > a {
                        color: $color-darkgray-3;
                        }

                    /* Hover and Current States */
                    &:hover,
                    &.current {
                        > a {
                            color: $color-green-3;
                            }
                    }
                }
            }

            /* Column Social Navigation List */
			&.menu {
				margin: 0;
				padding: 0;
				list-style-type: none;

				> li {
					padding: 10px 0;
					display: block;
					font-size: 0.975rem;
					font-weight: 300;
					line-height: 1.25rem;

					> a {}

                    /* Hover State */
					&:hover,
					&.current_page_item {
						> a {
							color: $color-darkgray-3;
						    }
					}
				}

				/* Social Navigation */
				&.menu-ftr-social-nav-list {
                    float: right;

					> li {
						margin: 0;
						padding: 0 15px 0 0;
						display: block;
						float: left;

						> a {
							display: block;
							color: $color-darkgray-3;
							font-size: 1.10rem;
							text-align: center;
							line-height: 1.45rem;

							@include opacity(0.80);
							@include rounded(50%);
							@include transition(
								color 0.10s ease-in-out,
								border-color 0.10s ease-in-out,
								background-color 0.10s ease-in-out,
								opacity 0.10s ease-in-out
							);

							&::before {
								font-family: $fabrands;
								}

							&:hover {
								color: $color-green-2;

								@include opacity(1.00);
								}
						}

						&:last-child { padding-right: 0; }

						/* Icon: Blogger */
						&.blogger {
							a {

								/* https://fontawesome.com/icons/blogger-b?style=brands */
								&::before { content: '\f37d'; }
							}
						}

						/* Icon: Delicious */
						&.delicious {
							a {

								/* https://fontawesome.com/icons/delicious?style=brands */
								&::before { content: '\f1a5'; }
							}
						}

						/* Icon: digg */
						&.digg {
							a {

								/* https://fontawesome.com/icons/digg?style=brands */
								&::before { content: '\f1a6'; }
							}
						}

						/* Icon: dribbble */
						&.dribbble {
							a {

								/* https://fontawesome.com/icons/dribbble?style=brands */
								&::before { content: '\f17d'; }
							}
						}

						/* Icon: Facebook */
						&.facebook {
							a {

								/* https://fontawesome.com/icons/facebook-f?style=brands */
								&::before { content: '\f39e'; }
							}
						}

						/* Icon: Instagram */
						&.instagram {
							a {

								/* https://fontawesome.com/icons/instagram?style=brands */
								&::before { content: '\f16d'; }
							}
						}

						/* Icon: LinkedIn */
						&.linkedin {
							a {

								/* https://fontawesome.com/icons/linkedin-in?style=brands */
								&::before { content: '\f0e1'; }
							}
						}

						/* Icon: Mix */
						&.mix {
							a {

								/* https://fontawesome.com/icons/mix?style=brands */
								&::before { content: '\f3cb'; }
							}
						}

						/* Icon: Pinterest */
						&.pinterest {
							a {

								/* https://fontawesome.com/icons/pinterest-p?style=brands */
								&::before { content: '\f231'; }
							}
						}

						/* Icon: Reddit */
						&.reddit {
							a {

								/* https://fontawesome.com/icons/reddit-alien?style=brands */
								&::before { content: '\f281'; }
							}
						}

						/* Icon: Twitter */
						&.twitter {
							a {

								/* https://fontawesome.com/icons/twitter?style=brands */
								&::before { content: '\f099'; }
							}
						}

						/* Icon: Tumblr */
						&.tumblr {
							a {

								/* https://fontawesome.com/icons/tumblr?style=brands */
								&::before { content: '\f173'; }
							}
						}

						/* Icon: Vimeo */
						&.vimeo {
							a {

								/* https://fontawesome.com/icons/vimeo-v?style=brands */
								&::before { content: '\f27d'; }
							}
						}

						/* Icon: Youtube */
						&.youtube {
							a {

								/* https://fontawesome.com/icons/youtube?style=brands */
								&::before { content: '\f167'; }
							}
						}

					}
				}
			}
		}

		section {
			padding: 0 0 35px;

			&:last-child {
				padding-bottom: 0;
				}
		}

		/* Logo Position */
		.column-footer-logo {
			padding-bottom: 30px;
			}

		/* Subscribe to Mailing List Position */
		.column-footer-subscribe {
			padding-top: 35px;

			h3 {
				color: $color-white;

				&::before {
					background-color: $color-white;
					}
			}

			/* Submit Form */
			.footer-subscribe-form {
				padding: 5px 0 0;

				.footer-subscribe-frame {
					position: relative;
					padding: 0 35px 0 0;
					max-width: 385px;

					/* Submit Button */
					button {
						&.footer-submit-btn {
							position: absolute;
							top: 0;
							right: 0;
							z-index: 1;
							margin: 0;
							display: block;
							width: 35px;
							height: 35px;
							color: $color-white;
							font-size: 1.10rem;
							line-height: 35px;
							text-align: center;
							cursor: pointer;
							border: 1px solid $color-lightgray-1;
							border-width: 1px 1px 1px 0;
							background-color: $color-orange-2;

							@include transition(
								border-color 0.10s ease-in-out,
								background-color 0.10s ease-in-out
							);

							&::before {
								font-family: $lmi;

								/* https://fontawesome.com/icons/search?style=regular */
								content: '\e900';
								}

							&:hover {
								background-color: $color-orange-3;
								}
						}
					}

					/* Subscribe Field */
					.footer-subscribe-field {
						margin: 0;
						padding: 0 15px;
						display: block;
						width: 100%;
						height: 35px;
						border: 1px solid $color-lightgray-1;
						border-width: 1px 0 1px 1px;
						background-color: $color-white;

						@include transition(border-color 0.10s ease-in-out);

						&:hover {
							border-color: $color-orange-2;

							+ button {
								&.btn-header-search {
									border-color:  $color-orange-3;
									}
							}
						}

						&:focus {
							border-color: $color-orange-2;

							+ button {
								&.btn-header-search {
									border-color:  $color-orange-3;
									}
							}
						}
					}

				}
			}
		}

	}
}

/* Media Queries */
/* 550px */
@media screen and (max-width: 550px){
    .section-footer {
        .sec-footer {

            .col-4,
            .col-8 {
                flex: 0 0 100%;
                width: 100%;
                max-width: 100%;
                }

            p {
                &.copyright {
                    padding: 0 0 5px;
                    float: none;
                    text-align: center;
                    }
            }

            ul {
                &.footer-menu {
                    padding-bottom: 10px;
                    text-align: center;

                    > li {
                        display: inline-block;
                        }
                }

                &.menu {
                    &.menu-ftr-social-nav-list {
                        float: none;
                        width: 100%;
                        text-align: center;

                        &::after {
                            clear: both;
                            margin: 0;
                            padding: 0;
                            display: table;
                            width: 0;
                            height: 0;
                            font-size: 0;
                            line-height: 0;
                            content: ' ';
                            overflow: hidden;
                            visibility: hidden;
                            *zoom: 1;
                            }

                        > li {
                            padding: 0 8px;
                            display: inline-block;
                            float: none;
                            }
                    }
                }
            }
        }
    }
}


/* Cookie Consent */
.js-cookie-consent {
    &.cookie-consent {
        padding: 15px !important;

        .cookie-consent-body {
            position: relative;
            padding: 0;

            span {
                &.cookie-consent__message {
                    padding: 15px 0;
                    display: block;
                    float: left;
                    height: auto !important;
                    line-height: 1.35rem !important;
                    }
            }

            button {
                &.js-cookie-consent-agree {
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    z-index: 2;
                    margin: 0;
                    padding: 10px 15px !important;
                    display: block;
                    float: right;
                    width: 100%;
                    max-width: 175px;
                    font-size: 0.80rem;
                    cursor: pointer;

                    @include rounded(3px !important);
                    @include transform(translateY(-50%));
                    @include transition(background-color 0.10s ease-in-out);

                    &:hover {
                        background-color: $color-green-2 !important;
                        }
                }

                &.cookie-consent__agree {}
            }
        }
    }
}

/* Media Queries */
/* 850px */
@media screen and (max-width: 850px){

    /* Cookie Consent */
    .js-cookie-consent {
        &.cookie-consent {
            .cookie-consent-body {

                span {
                    &.cookie-consent__message {
                        float: none;
                        text-align: center;
                        }
                }

                button {
                    &.js-cookie-consent-agree {
                        position: relative;
                        top: auto;
                        right: auto;
                        margin: 20px auto 0;
                        float: none;
                        }
                }
            }
        }
    }
}
