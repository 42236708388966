/* ************************************************************************************************ */
/* Grid ******************************************************************************************* */
/* ************************************************************************************************ */

/* Container */
.container {
    margin-right: auto;
    margin-left: auto;
    /*
    padding-right: 15px;
    padding-left: 15px;
    */
    }

/*
@media (min-width: 768px) {
    .container { max-width: 750px; }
}

@media (min-width: 992px) {
    .container { max-width: 970px; }
}

@media (min-width: 1200px) {
    .container { max-width: $maxwidth; }
}
*/

.container { max-width: $maxwidth; }

/* Media Queries */

/* 1200px */
@media screen and (min-width: 1200px){
	/* .container { max-width: $maxwidth + 200px; } */
}



/* Fluid Container */
.container-fluid {
    margin-right: 15px;
    margin-left: 15px;
	/*
    padding-right: 15px;
    padding-left: 15px;
	*/
    }

/* Row */
.row {
    margin: 0 -15px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    }

/* columns */
.col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    }

.col-lg-1,.col-md-1,.col-sm-1,.col-xs-1,
.col-lg-2,.col-md-2,.col-sm-2,.col-xs-2,
.col-lg-3,.col-md-3,.col-sm-3,.col-xs-3,
.col-lg-4,.col-md-4,.col-sm-4,.col-xs-4,
.col-lg-5,.col-md-5,.col-sm-5,.col-xs-5,
.col-lg-6,.col-md-6,.col-sm-6,.col-xs-6,
.col-lg-7,.col-md-7,.col-sm-7,.col-xs-7,
.col-lg-8,.col-md-8,.col-sm-8,.col-xs-8,
.col-lg-9,.col-md-9,.col-sm-9,.col-xs-9,
.col-lg-10,.col-md-10,.col-sm-10,.col-xs-10,
.col-lg-11,.col-md-11,.col-sm-11,.col-xs-11 { float: left; }

.col-1,.col-lg-1,.col-md-1,.col-sm-1,.col-xs-1,
.col-2,.col-lg-2,.col-md-2,.col-sm-2,.col-xs-2,
.col-3,.col-lg-3,.col-md-3,.col-sm-3,.col-xs-3,
.col-4,.col-lg-4,.col-md-4,.col-sm-4,.col-xs-4,
.col-5,.col-lg-5,.col-md-5,.col-sm-5,.col-xs-5,
.col-6,.col-lg-6,.col-md-6,.col-sm-6,.col-xs-6,
.col-7,.col-lg-7,.col-md-7,.col-sm-7,.col-xs-7,
.col-8,.col-lg-8,.col-md-8,.col-sm-8,.col-xs-8,
.col-9,.col-lg-9,.col-md-9,.col-sm-9,.col-xs-9,
.col-10,.col-lg-10,.col-md-10,.col-sm-10,.col-xs-10,
.col-11,.col-lg-11,.col-md-11,.col-sm-11,.col-xs-11,
.col,.col-12,.col-lg-12,.col-md-12,.col-sm-12,.col-sx-12 {
    padding: 0 15px;
    min-height: 1px;
    }


/* Non-lg/md/sm/xs */
.col,
.col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    }
.col-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    width: 91.666667%;
    max-width: 91.666667%;
    }
.col-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    width: 83.333333%;
    max-width: 83.333333%;
    }
.col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
    }
.col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    width: 66.666667%;
    max-width: 66.666667%;
    }
.col-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    width: 58.333333%;
    max-width: 58.333333%;
    }
.col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    }
.col-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    width: 41.666667%;
    max-width: 41.666667%;
    }
.col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    width: 33.333333%;
    max-width: 33.333333%;
    }
.col-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
    }
.col-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    width: 16.666667%;
    max-width: 16.666667%;
    }
.col-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    width: 8.333333%;
    max-width: 8.333333%;
    }

.no-flex {
    .row {
        display: block;
        }
    .col-12,col-11,.col-10,.col-9,.col-8,.col-7,.col-6,
    .col-5,.col-4,.col-3,.col-2,.col-1 {
        display: block;
        float: left;
        }
}


/* LG */
.col-lg-12 { width: 100.000%; }
.col-lg-11 { width:  91.666%; }
.col-lg-10 { width:  83.333%; }
.col-lg-9  { width:  75.000%; }
.col-lg-8  { width:  66.666%; }
.col-lg-7  { width:  58.333%; }
.col-lg-6  { width:  50.000%; }
.col-lg-5  { width:  41.666%; }
.col-lg-4  { width:  33.333%; }
.col-lg-3  { width:  25.000%; }
.col-lg-2  { width:  16.666%; }
.col-lg-1  { width:   8.333%; }


/* MD */
.col-md-12 { width: 100.000%; }
.col-md-11 { width:  91.666%; }
.col-md-10 { width:  83.333%; }
.col-md-9  { width:  75.000%; }
.col-md-8  { width:  66.666%; }
.col-md-7  { width:  58.333%; }
.col-md-6  { width:  50.000%; }
.col-md-5  { width:  41.666%; }
.col-md-4  { width:  33.333%; }
.col-md-3  { width:  25.000%; }
.col-md-2  { width:  16.666%; }
.col-md-1  { width:   8.333%; }


/* SM */
.col-sm-12 { width: 100.000%; }
.col-sm-11 { width:  91.666%; }
.col-sm-10 { width:  83.333%; }
.col-sm-9  { width:  75.000%; }
.col-sm-8  { width:  66.666%; }
.col-sm-7  { width:  58.333%; }
.col-sm-6  { width:  50.000%; }
.col-sm-5  { width:  41.666%; }
.col-sm-4  { width:  33.333%; }
.col-sm-3  { width:  25.000%; }
.col-sm-2  { width:  16.666%; }
.col-sm-1  { width:   8.333%; }


/* XS */
.col-xs-12 { width: 100.000%; }
.col-xs-11 { width:  91.666%; }
.col-xs-10 { width:  83.333%; }
.col-xs-9  { width:  75.000%; }
.col-xs-8  { width:  66.666%; }
.col-xs-7  { width:  58.333%; }
.col-xs-6  { width:  50.000%; }
.col-xs-5  { width:  41.666%; }
.col-xs-4  { width:  33.333%; }
.col-xs-3  { width:  25.000%; }
.col-xs-2  { width:  16.666%; }
.col-xs-1  { width:   8.333%; }


/* Pull */
.col-xs-pull-12 { right: 100.000%; }
.col-xs-pull-11 { right:  91.666%; }
.col-xs-pull-10 { right:  83.333%; }
.col-xs-pull-9  { right:  75.000%; }
.col-xs-pull-8  { right:  66.666%; }
.col-xs-pull-7  { right:  58.333%; }
.col-xs-pull-6  { right:  50.000%; }
.col-xs-pull-5  { right:  41.666%; }
.col-xs-pull-4  { right:  33.333%; }
.col-xs-pull-3  { right:  25.000%; }
.col-xs-pull-2  { right:  16.666%; }
.col-xs-pull-1  { right:   8.333%; }
.col-xs-pull-0  { right: auto; }


/* Push */
.col-xs-push-12 { left: 100.000%; }
.col-xs-push-11 { left:  91.666%; }
.col-xs-push-10 { left:  83.333%; }
.col-xs-push-9  { left:  75.000%; }
.col-xs-push-8  { left:  66.666%; }
.col-xs-push-7  { left:  58.333%; }
.col-xs-push-6  { left:  50.000%; }
.col-xs-push-5  { left:  41.666%; }
.col-xs-push-4  { left:  33.333%; }
.col-xs-push-3  { left:  25.000%; }
.col-xs-push-2  { left:  16.666%; }
.col-xs-push-1  { left:   8.333%; }
.col-xs-push-0  { left: auto; }


/* Offset */
.col-xs-offset-12 { margin-left: 100.000%; }
.col-xs-offset-11 { margin-left:  91.666%; }
.col-xs-offset-10 { margin-left:  83.333%; }
.col-xs-offset-9  { margin-left:  75.000%; }
.col-xs-offset-8  { margin-left:  66.666%; }
.col-xs-offset-7  { margin-left:  58.333%; }
.col-xs-offset-6  { margin-left:  50.000%; }
.col-xs-offset-5  { margin-left:  41.666%; }
.col-xs-offset-4  { margin-left:  33.333%; }
.col-xs-offset-3  { margin-left:  25.000%; }
.col-xs-offset-2  { margin-left:  16.666%; }
.col-xs-offset-1  { margin-left:   8.333%; }
.col-xs-offset-0  { margin-left:   0.000%; }


.well {
    margin: 0 0 20px;
    padding: 15px;
    background-color: $color-white;

    @include rounded(3px);
    }

.form-group {
    padding: 5px 0;
    }
