/* ************************************************************************************************ */
/* Topics List ************************************************************************************ */
/* ************************************************************************************************ */

#section-main {
    .sec-main {

        .topics-filters-container {
            padding: 0 0 45px;

            .topics-filter-item {
                &.search {
                    position: relative;
                    margin-right: 15px;
                    padding-right: 70px;
                    float: left;
                    width: 450px;

                    .button {
                        position: absolute;
                        top: 0;
                        right: 0;
                        z-index: 1;
                        margin: 0;
                        padding: 0;
                        width: 65px;
                        height: 40px;
                        line-height: 38px;
                        text-align: center;

                        &::before {
                            font-family: $faregular;

                            /* https://fontawesome.com/icons/search?style=regular*/
                            content: '\f002';
                            }
                    }
                }

                &.models {
                    margin-left: 15px;
                    float: right;
                    width: 275px;
                    }

                &.categories {
                    float: right;
                    width: 275px;
                    }
            }
        }

        .topics-container {
            ul {
                &.topics-list {
                    margin: 0 -15px;
                    padding: 0;
                    list-style-type: none;
                    display: grid;
                    grid-template-columns: auto auto auto;
                    grid-template-rows: auto;
                    grid-auto-rows: 1fr;

                    > li {
                        &.topic-item {
                            margin: 0 0 30px;
                            padding: 0 15px;
                            display: block;

                            > a {
                                position: relative;
                                margin: 0;
                                padding: 0;
                                display: block;
                                height: 100%;
                                color: $color-white;
                                text-decoration: none;
                                overflow: hidden;
                                border: 1px solid $color-lightgray-2;

                                @include rounded(8px);
                                @include transition(all 0.15s ease-in-out);

                                span {
                                    display: block;

                                    /* Content */
                                    &.topic-item-content {
                                        position: relative;
                                        padding: 140px 20px 100px;
                                        padding: 30px;
                                        width: 100%;
                                        height: 100%;
                                        overflow: hidden;
                                        background-image: url("../images/logos/logo-r1-black-bkg-no-reg.svg");
                                        background-position: right center;
                                        background-repeat: no-repeat;

                                        .topic-item-content-outer {
                                            display: table;
                                            height: 100%;
                                            }

                                        .topic-item-content-inner {
                                            display: table-cell;
                                            vertical-align: middle;
                                            }
                                    }

                                    .topic-item-name {
                                        padding: 0 0 10px;
                                        font-size: 1.95rem;
                                        font-weight: bold;
                                        line-height: 2.35rem;
                                        }
                                    .topic-item-subname-1 {
                                        padding: 0 0 5px;
                                        font-size: 1.25rem;
                                        font-weight: bold;
                                        line-height: 1.55rem;
                                        }
                                    .topic-item-subname-2 {
                                        padding: 0 0 5px;
                                        font-size: 1.25rem;
                                        font-weight: 300;
                                        line-height: 1.55rem;
                                        }
                                    .topic-item-subname-3 {
                                        font-size: 1.05rem;
                                        font-weight: bold;
                                        line-height: 1.35rem;
                                        }
                                    .topic-item-subname-logo {}
                                }
                            }

                            &:hover {
                                > a {
                                    background-color: rgba($color-lightgray-1,0.15);

                                    @include boxshadow(0 0 20px rgba($color-black,0.20));
                                    @include opacity(0.80);
                                    }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Media Queries */
/* 1200px */
@media screen and (max-width: 1200px){
    #section-main {
        .sec-main {
            .topics-container {
                ul{
                    &.topics-list {
                        grid-template-columns: auto auto;
                        }
                }
            }
        }
    }
}

/* 650px */
@media screen and (max-width: 650px){
    #section-main {
        .sec-main {
            .topics-container {
                ul{
                    &.topics-list {
                        grid-template-columns: auto;
                        }
                }
            }
        }
    }
}
