.topic-info-content {
    padding-top: 30px;
    padding-bottom: 30px;

    > .row {
        margin: 0 -30px;

        > .col-1,
        > .col-2,
        > .col-3,
        > .col-4,
        > .col-5,
        > .col-6,
        > .col-7,
        > .col-8,
        > .col-9,
        > .col-10,
        > .col-11,
        > .col-12 {
            padding-right: 30px;
            padding-left: 30px;
            }
    }

    .featured-image,
    .featured-video {
        padding: 0 0 20px;
        }

    .featured-image {
        img {
            display: block;
            width: 100%;
            }
    }

    .featured-image,
    .featured-video {
        height: auto;

        /* Make IFrame Video Container Responsive */
        .iframe_container {
            position: relative;
            padding: 0 0 56.25%; /* 16:9 */
            height: 0;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                }
        }

        #r1l_local_video_container {
            position: relative;
            /* padding: 0 0 56.25%; */ /* 16:9 */
            height: 0;
            }
    }
}

/* Media Queries */
@media screen and (max-width: 850px){
    .topic-info-content {

        > .row {

            > .col-1,
            > .col-2,
            > .col-3,
            > .col-4,
            > .col-5,
            > .col-6,
            > .col-7,
            > .col-8,
            > .col-9,
            > .col-10,
            > .col-11,
            > .col-12 {
                float: none;
                flex: 0 0 100%;
                width: 100%;
                max-width: 100%;
                }
        }
    }
}
