/* ************************************************************************************************ */
/* Page Banner ************************************************************************************ */
/* ************************************************************************************************ */
#section-main {
    .sec-main {
		.sec-main-inner {
            .content-area {

                .r1l-page-banner {
                    position: relative;
                    padding-top: 35px;
                    padding-bottom: 35px;

                    > .container {
                        padding-right: 15px;
                        padding-left: 15px;
                        }

                    .r1l-page-banner-outer {}
                    .r1l-page-banner-inner {
                        position: relative;

                        h2 {
                            padding: 0;
                            color: $color-white;
                            }
                    }

                    &.has-breadcrumbs {
                        padding-top: 5px;
                        padding-bottom: 5px;

                        h2,p {
                            position: absolute !important;
                            top: -9999em !important;
                            left: -9999em !important;
                            margin: -1px !important;
                            padding: 0 !important;
                            display: block !important;
                            width: 1px !important;
                            height: 1px !important;
                            font-size: 1px !important;
                            line-height: 1px !important;
                            overflow: hidden !important;
                            border: 0 none !important;
                            outline: 0 none !important;
                            clip: rect(0,0,0,0) !important;
                            }
                    }
                }

			}
		}
	}
}
