/* ************************************************************************************************ */
/* Page Frame ************************************************************************************* */
/* ************************************************************************************************ */
#section-main {
    .sec-main {
        background-color: $color-white;

        .sec-main-inner {

            /* Content Areas */
            .content-area {

                > .container {
                    padding-right: 15px;
                    padding-left: 15px;
                    max-width: 1400px;
                    }

                /* Row Containers: Repeater Content Blocks */
                .container-repeater {
                    padding-top: 60px;
                    padding-bottom: 60px;

                    &.callout-block {
                        padding-top: 60px;
                        padding-bottom: 60px;

                        .callout-content-wrapper {
                            margin: 0 auto;
                            max-width: 800px;
                            }

                        h1,h2,h3,h4,h5,h6,p {
                            text-align: center;
                            }
                    }
                }
            }

        }
    }
}

/* Media Queries */
/* 1200px (min-width) */
@media screen and (min-width: 1200px) {
    #section-main {
        .sec-main {
            .sec-main-inner {

                /* Content Areas */
                .content-area {}

            }
        }
    }
}



/* Elementor: Section Main */
body {
	&.elementor-page {
		#section-main {
		    .sec-main {
		        .sec-main-inner {

                    /*
		            > .container {
			            padding-right: 5px;
			            padding-left: 5px;

		                > .row {
			                margin: 0 -5px;

			                > .col-12 {
				                padding-right: 5px;
				                padding-left: 5px;
				                }
		                }
		            }
                    */

		        }
		    }
		}
	}
}
