/* Elite Video Player: Play Button */
.elite_vp_playBtnBg {
    background-color: $color-green-r1 !important;

    @include transition(all 0.10s ease-in-out);

    /* Hover State */
    &:hover {
        background-color: rgba($color-green-r1,0.70) !important;
        }
}

/* Elite Video Player: Progress Bar */
.elite_vp_Progress {
    background-color: $color-green-r1 !important;
    }

.elite_vp_mainContainer {
    background-color: transparent !important;
    }
