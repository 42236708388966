/* ************************************************************************************************ */
/* Loaders **************************************************************************************** */
/* ************************************************************************************************ */

/* Loading Spinner */
.vs-ajax-loading {
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 99999;
	margin: -30px 0 0 -30px;
	display: block;
	width: 80px;
	height: 80px;
	background-color: $color-white;
	background-color: rgba($color-white,0.80);
	
	@include rounded(4px);
	@include boxshadow(0 0 30px rgba($color-violet-2,0.15));
	
	.vs-ajax-loading-outer {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1;
		margin: -25px 0 0 -25px;
		width: 50px;
		height: 50px;
		border: 8px solid $color-violet-2;
		
		@include rounded(50%);
		
		&::before {
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 2;
			margin: -25px 0 0 -25px;
			width: 50px;
			height: 50px;
			content: ' ';
			border-color: #b25ff2 transparent transparent;
			border-style: solid;
			border-width: 8px;
			
			@include rounded(50%);
			-webkit-animation:  spin 1s linear infinite;
			-moz-animation:     spin 1s linear infinite;
			-ms-animation:      spin 1s linear infinite;
			-o-animation:       spin 1s linear infinite;
			animation:          spin 1s linear infinite;
			}
	}
	.vs-ajax-loading-inner {}
}



/* Tooltip */
.copy-share-tooltip {
	position: absolute;
	top: 100%;
	left: 50%;
	margin: 10px 0 0 -55px;
	display: none;
	width: 110px;
	color: $color-violet-2;
	font-size: 0.65rem;
	text-align: center;
	border: 1px solid $color-lightgray-5;
	background-color: $color-lightgray-1;
	
	@include rounded(2px);
	@include userselect(none);
	}