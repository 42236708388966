/* ************************************************************************************************ */
/* Sidebars *************************************************************************************** */
/* ************************************************************************************************ */
#section-main {
	.sec-main {

		.sidebar {

            .button-container {
                padding: 0 0 45px;
                text-align: center;

                .button {
                    margin: 0 0 5px;
                    padding-right: 5px;
                    padding-left: 5px;
                    display: inline-block;
                    width: 100%;
                    max-width: 275px;

                    @include transition(all 0.10s ease-in-out);

                    &.btn-view-report {
                        border-color: $color-darkgray-1;
                        background-color: $color-darkgray-1;
                        }

                    &:last-child {}

                    /* Hover State */
                    &:hover {
                        border-color: $color-green-5;
                        background-color: $color-green-5;
                        }

                    &.btn-exit-activity {


                        /* Hover State */
                        &:hover {
                            border-color: $color-red-5;
                            background-color: $color-red-5;

                            @include opacity(1.00);
                            }
                    }
                }
            }

            ul {
                &.sidebar-links-list {
                    margin: 0;
                    padding: 0 0 45px;
                    list-style-type: none;

                    &:last-child {
                        padding-bottom: 0;
                        }

                    > li {
                        margin: 0;
                        padding: 0;
                        display: block;

                        > a {
                            padding: 10px 0;
                            display: block;
                            color: $color-darkgray-2;
                            text-decoration: none;
                            border-bottom: 1px dotted $color-lightgray-4;
                            }

                        &:first-child {
                            > a {
                                border-top: 1px dotted $color-lightgray-4;
                                }
                        }

                        /* Hover State */
                        &:hover {
                            > a {
                                color: $color-orange-2;
                                }
                        }
                    }

                    &.individual-activities {}

                    &.activity-instructions {
                        > li {
                            padding: 8px 0;
                            display: block;
                            font-size: 1.05rem;
                            font-weight: normal;
                            font-weight: 500;
                            line-height: 1.35rem;
                            }
                    }
                }
            }

            /* Topics Info Page Sidebar */
            &.topic-sidebar,
            &.activity-sidebar {
                h3,
                h4 {
                    padding-bottom: 5px;
                    font-size: 1.45rem;
                    font-weight: bold;
                    font-weight: 600;
                    line-height: 1.75rem;
                    }

                p {
                    margin: 0;
                    padding: 0 0 15px;
                    }

                ul {
                    margin: 0;
                    padding: 0 0 20px;
                    list-style-type: none;

                    > li {
                        position: relative;
                        margin: 0;
                        padding: 0;
                        display: block;

                        &::before {
                            position: absolute;
                            top: 11px;
                            left: 0;
                            display: block;
                            width: 5px;
                            height: 5px;
                            content: ' ';
                            background-color: $color-black;

                            @include rounded(50%);
                            }

                        > a,
                        > span {
                            margin: 0;
                            padding: 3px 0 3px 15px;
                            display: block;
                            color: $color-darkgray-2;
                            text-decoration: none;
                            }

                        /* Hover State */
                        &:hover {
                            > a {
                                color: $color-orange-2;
                                }
                        }
                    }
                }

                select {
                    &.activity_program_dropdown {
                        margin:  0 auto 10px;
                        max-width: 275px;
                        }
                }
            }
		}
	}
}

/* Media Queries */
/* 500px */
@media screen and (max-width: 500px){
    #section-main {
        .sec-main {

            .sidebar {

                .button-container {
                    padding: 30px 0;

                    .button {
                        float: none;
                        width: 100%;
                        }
                }

                select {
                    &.activity_program_dropdown {
                        margin:  0 auto 10px;
                        max-width: 275px;
                        }
                }

            }
        }
    }
}
