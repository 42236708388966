/* ************************************************************************************************ */
/* Content **************************************************************************************** */
/* ************************************************************************************************ */
.content-area {

    a {
		color: $color-orange-2;
		text-decoration: underline;

        &:hover {
            color: $color-orange-3;
            }
    }

    h1 {
		position: relative;
		margin: 0;
		padding: 75px 0 65px;
		color: $color-darkgray-2;
		font-family: $font2;
		font-size: 2.85rem;
		font-weight: normal;
		font-weight: 400;
        line-height: 3.05rem;
		}

    h2 {
	    position: relative;
		margin: 0;
		padding: 5px 0 20px;
		color: $color-darkgray-2;
		font-family: $font1;
		font-size: 2.25rem;
		font-weight: bold;
		font-weight: 600;
        line-height: 2.75rem;

	    &.entry-title {
		    &.dash {}
	    }
	}

    h3 {
		margin: 0;
		padding: 0 0 15px;
		color: $color-darkgray-2;
		font-family: $font1;
		font-size: 1.75rem;
		font-weight: normal;
		font-weight: 300;
		line-height: 2.15rem;
		}

    h4 {
		margin: 0;
		padding: 15px 0 0;
		color: $color-darkgray-2;
		font-size: 1.35rem;
        font-weight: bold;
		font-weight: 600;
		line-height: 1.45rem;
		}

    h5 {
		margin: 0;
		padding: 10px 0;
		color: $color-darkgray-2;
		font-size: 0.90rem;
		font-weight: bold;
		font-weight: 700;
		}

    h6 {
		margin: 0;
		color: $color-darkgray-2;
		}

    p {
		margin: 0;
		padding: 15px 0;
		color: $color-darkgray-2;
		font-size: 1.00rem;
		line-height: 1.65rem;

	    /* Header Widget */
	    &.ntc-elementor-heading-title {
		    margin-bottom: -10px;
		    padding-bottom: 0;
		    }
	}

	/* Header Bar */
	.header-bar {
		position: absolute;
		width: 40px;
		height: 3px;

		&.orange { background-color: $color-orange-3; }
		&.violet { background-color: $color-violet-2; }
	}

	/* Header Bar */
	h1,h2,h3,h4,h5,h6,p {

		/* Header Bar Position: Right */
		&.header-bar-placement-right {
			position: relative;
			padding-right: 60px;
			padding-bottom: 15px;
			display: inline-block;

			/* Header Bar */
			.header-bar {
				right: 0;
				}
		}

		/* Header Bar Position: Bottom */
		&.header-bar-placement-bottom {
			position: relative;
			padding-bottom: 65px;
			display: inline-block;

			/* Header Bar */
			.header-bar {
				left: 0;
				}
		}
	}

	/* Header Bar: h2 */
	h2 {

		/* Header Bar Position: Right */
		&.header-bar-placement-right {
			.header-bar { top: 45px; }
		}

		/* Header Bar Position: Bottom */
		&.header-bar-placement-bottom {
			.header-bar { bottom: 15px; }
		}
	}

	/* Header Bar: h3 */
	h3 {

		/* Header Bar Position: Right */
		&.header-bar-placement-right {
			.header-bar { top: 20px; }
		}

		/* Header Bar Position: Bottom */
		&.header-bar-placement-bottom {
			.header-bar { bottom: 10px; }
		}
	}

    code {}

    address {}

    blockquote {
		position: relative;
		margin: 30px 0;
		padding: 5px 0 0 38px;
		display: block;

		&::before {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			display: block;
			width: 48px;
			height: 48px;
			color: $color-lightgray-2;
			font-family: $fasolid;
			font-size: 3.00rem;
			line-height: 48px;
			text-align: center;

			/* https://fontawesome.com/icons/quote-left?style=solid */
			content: '\f10d';
			}

		h5 {
			position: relative;
			z-index: 2;
			padding: 0;
			}
		p {
			position: relative;
			z-index: 2;
			font-style: italic;
			font-weight: bold;
			font-weight: 500;
			}
	}

    li {
		margin: 0;
		padding: 4px 0;
		color: $color-darkgray-2;
		font-size: 1.00rem;
		line-height: 1.45rem;
		}

    ul {
		margin: 10px 0 10px 15px;
		padding: 0;
	    list-style-type: disc;

		li {
			padding-left: 5px;
			}

		> li {

			> ul {
				margin-top: 4px;
				margin-bottom: 0;
				list-style-type: circle;

				> li {

					> ul {
						margin-top: 4px;
						margin-bottom: 0;
						list-style-type: square;

						> li {}
					}
				}
			}
		}

	    /* Icon - First Level: Checkmark */
	    &.checkmark {
		    margin-left: 0;
		    list-style-type: none;

		    > li {
			    position: relative;
			    padding-left: 30px;

			    &::before {
				    position: absolute;
				    top: 8px;
				    left: 0;
				    width: 16px;
				    height: 16px;
				    color: $color-violet-2;
				    font-family: $fasolid;
				    font-size: 1.05rem;
				    line-height: 16px;
				    text-align: center;

				    /* https://fontawesome.com/icons/check?style=solid */
				    content: '\f00c';
				    }
		    }
	    }
    }

    ol {
		margin: 10px 0 10px 15px;
	    padding: 0;
	    list-style-type: decimal;

		li {
			padding-left: 5px;
			}

		> li {

			> ol {
				margin-top: 4px;
				margin-bottom: 0;
				list-style-type: upper-alpha;

				> li {

					> ol {
						margin-top: 4px;
						margin-bottom: 0;
						list-style-type: lower-alpha;

						> li {}
					}
				}
			}
		}
	}
}


/* Table */
table {
    &.table {
        width: 100%;
        max-width: 100%;
        border-collapse: collapse;

        &.max-width-25  { max-width:  25% !important; }
        &.max-width-50  { max-width:  50% !important; }
        &.max-width-75  { max-width:  75% !important; }
        &.max-width-100 { max-width: 100% !important; }

        tr {}
        td {}
        th {}

        thead {
            tr {}
            td {}

            th,
            td {
                padding: 0.85rem;
                text-align: inherit;
                vertical-align: bottom;
                border-top: 1px solid $color-lightgray-4;
                border-bottom: 2px solid $color-lightgray-4;
                }

            &.thead-light {}
            &.thead-dark {}
        }

        tbody {
            tr {}
            th,
            td {
                padding: 0.85rem;
                text-align: inherit;
                vertical-align: top;
                border-top: 1px solid $color-lightgray-4;
                }
        }

        /* Table Striped */
        &.table-striped {
            tbody {
                tr {
                    &:nth-of-type(2n+1){
                        background-color: rgba($color-black,0.05);
                        }
                }
                th {}
                td {}
            }
        }

        /* Table Bordered */
        &.table-bordered {
            border: 1px solid $color-lightgray-4;
            }

        /* Table Dark */
        &.table-dark {
            color: $color-white;
            border: 0;
            background-color: $color-darkgray-3;

            thead {
                tr {}
                th {
                    border-color: $color-darkgray-1;
                    }
                td {
                    border-color: $color-darkgray-1;
                    }
            }

            tbody {
                tr {}
                th {
                    border-color: $color-darkgray-1;
                    }
                td {
                    border-color: $color-darkgray-1;
                    }
            }

            /* Striped Dark Table */
            &.table-striped {
                tbody{
                    tr {
                        &:nth-of-type(2n+1){
                            background-color: $color-darkgray-2;
                            }
                    }
                }
            }
        }
    }
}


/* Font Weights */
.weight-100	{ font-weight: normal !important; font-weight: 100 !important; }
.weight-200	{ font-weight: normal !important; font-weight: 200 !important; }
.weight-300	{ font-weight: normal !important; font-weight: 300 !important; }
.weight-400	{ font-weight: normal !important; font-weight: 400 !important; }
.weight-500	{ font-weight:   bold !important; font-weight: 500 !important; }
.weight-600	{ font-weight:   bold !important; font-weight: 600 !important; }
.weight-700	{ font-weight:   bold !important; font-weight: 700 !important; } /* Should use <b> or <strong> */
.weight-800	{ font-weight:   bold !important; font-weight: 800 !important; }
.weight-900	{ font-weight:   bold !important; font-weight: 900 !important; }


/* Font Sizes */
.fontsize-5px  { font-size:  5px !important; }
.fontsize-6px  { font-size:  6px !important; }
.fontsize-7px  { font-size:  7px !important; }
.fontsize-8px  { font-size:  8px !important; }
.fontsize-9px  { font-size:  9px !important; }
.fontsize-10px { font-size: 10px !important; }
.fontsize-11px { font-size: 11px !important; }
.fontsize-12px { font-size: 12px !important; }
.fontsize-13px { font-size: 13px !important; }
.fontsize-14px { font-size: 14px !important; }
.fontsize-15px { font-size: 15px !important; }
.fontsize-16px { font-size: 16px !important; }
.fontsize-17px { font-size: 17px !important; }
.fontsize-18px { font-size: 18px !important; }
.fontsize-19px { font-size: 19px !important; }
.fontsize-20px { font-size: 20px !important; }
.fontsize-21px { font-size: 21px !important; }
.fontsize-22px { font-size: 22px !important; }
.fontsize-23px { font-size: 23px !important; }
.fontsize-24px { font-size: 24px !important; }
.fontsize-25px { font-size: 25px !important; }
.fontsize-26px { font-size: 26px !important; }
.fontsize-27px { font-size: 27px !important; }
.fontsize-28px { font-size: 28px !important; }
.fontsize-29px { font-size: 29px !important; }
.fontsize-30px { font-size: 30px !important; }
.fontsize-31px { font-size: 31px !important; }
.fontsize-32px { font-size: 32px !important; }
.fontsize-33px { font-size: 33px !important; }
.fontsize-34px { font-size: 34px !important; }
.fontsize-35px { font-size: 35px !important; }
.fontsize-36px { font-size: 36px !important; }
.fontsize-37px { font-size: 37px !important; }
.fontsize-38px { font-size: 38px !important; }
.fontsize-39px { font-size: 39px !important; }
.fontsize-40px { font-size: 40px !important; }
.fontsize-41px { font-size: 41px !important; }
.fontsize-42px { font-size: 42px !important; }
.fontsize-43px { font-size: 43px !important; }
.fontsize-44px { font-size: 44px !important; }
.fontsize-45px { font-size: 45px !important; }
.fontsize-46px { font-size: 46px !important; }
.fontsize-47px { font-size: 47px !important; }
.fontsize-48px { font-size: 48px !important; }
.fontsize-49px { font-size: 49px !important; }
.fontsize-50px { font-size: 50px !important; }


/* Line Height */
.lineheight-5px  { line-height:  5px !important; }
.lineheight-6px  { line-height:  6px !important; }
.lineheight-7px  { line-height:  7px !important; }
.lineheight-8px  { line-height:  8px !important; }
.lineheight-9px  { line-height:  9px !important; }
.lineheight-10px { line-height: 10px !important; }
.lineheight-11px { line-height: 11px !important; }
.lineheight-12px { line-height: 12px !important; }
.lineheight-13px { line-height: 13px !important; }
.lineheight-14px { line-height: 14px !important; }
.lineheight-15px { line-height: 15px !important; }
.lineheight-16px { line-height: 16px !important; }
.lineheight-17px { line-height: 17px !important; }
.lineheight-18px { line-height: 18px !important; }
.lineheight-19px { line-height: 19px !important; }
.lineheight-20px { line-height: 20px !important; }
.lineheight-21px { line-height: 21px !important; }
.lineheight-22px { line-height: 22px !important; }
.lineheight-23px { line-height: 23px !important; }
.lineheight-24px { line-height: 24px !important; }
.lineheight-25px { line-height: 25px !important; }
.lineheight-26px { line-height: 26px !important; }
.lineheight-27px { line-height: 27px !important; }
.lineheight-28px { line-height: 28px !important; }
.lineheight-29px { line-height: 29px !important; }
.lineheight-30px { line-height: 30px !important; }
.lineheight-31px { line-height: 31px !important; }
.lineheight-32px { line-height: 32px !important; }
.lineheight-33px { line-height: 33px !important; }
.lineheight-34px { line-height: 34px !important; }
.lineheight-35px { line-height: 35px !important; }
.lineheight-36px { line-height: 36px !important; }
.lineheight-37px { line-height: 37px !important; }
.lineheight-38px { line-height: 38px !important; }
.lineheight-39px { line-height: 39px !important; }
.lineheight-40px { line-height: 40px !important; }
.lineheight-41px { line-height: 41px !important; }
.lineheight-42px { line-height: 42px !important; }
.lineheight-43px { line-height: 43px !important; }
.lineheight-44px { line-height: 44px !important; }
.lineheight-45px { line-height: 45px !important; }
.lineheight-46px { line-height: 46px !important; }
.lineheight-47px { line-height: 47px !important; }
.lineheight-48px { line-height: 48px !important; }
.lineheight-49px { line-height: 49px !important; }
.lineheight-50px { line-height: 50px !important; }


/* Text Align */
.align-left 	{ text-align: left !important; }
.align-center 	{ text-align: center !important; }
.align-right 	{ text-align: right !important; }
.align-justify 	{ text-align: justify !important; }


/**
 * Generates Margin and Padding Classes
 * Generates class values in increments of 5, from 0 to 100.
 * Margin E.g. .margin-all-0, .margin-all-20, .margin-top-10, .margin-left-100, etc.
 * Padding E.g. .padding-all-0, .padding-all-20, .padding-top-10, .padding-left-100, etc.
 */
@each $space in $spaceamounts {
	@each $side in $spacesides {
		@if $side == all {
			//.margin-#{str-slice($side, 0, 1)}-#{$space} {
			.margin-#{$side}-#{$space} {
				margin: #{$space}px !important;
			}
			//.padding-#{str-slice($side, 0, 1)}-#{$space} {
			.padding-#{$side}-#{$space} {
				padding: #{$space}px !important;
			}
		} @else {
			//.margin-#{str-slice($side, 0, 1)}-#{$space} {
			.margin-#{$side}-#{$space} {
				margin-#{$side}: #{$space}px !important;
			}
			//.padding-#{str-slice($side, 0, 1)}-#{$space} {
			.padding-#{$side}-#{$space} {
				padding-#{$side}: #{$space}px !important;
			}
		}

	}
}
