/* ************************************************************************************************ */
/* Buttons **************************************************************************************** */
/* ************************************************************************************************ */
.section-main {

    /* Buttons */
    .btn,
    .button {
        position: relative;
        margin: 0 10px 0 0;
        padding: 16px 45px;
        display: inline-block;
        color: $color-white;
        font-family: $font1;
        font-size: 1.00rem;
        font-weight: bold;
        font-weight: 700;
        line-height: 1.375rem;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        cursor: pointer;
        overflow: hidden;
        cursor: pointer;
        border: 2px solid $color-green-4;
        background-color: $color-green-4;

        @include transition(all 0.20s ease-in-out);
        @include rounded(3px);
        @include boxshadow(0 0 0 rgba($color-white,0.00));

        /* Hover State */
        &:hover {
            border-color: $color-green-5;
            background-color: $color-green-5;
            }

        /* Disabled State */
        &[disabled]{
            cursor: not-allowed;
            border-color: $color-gray-1;
            background-color: $color-gray-1;
            }

		/* Style: Outline */
		&.btn-outline {
			color: $color-green-2 !important;
			border: 1px solid $color-green-2 !important;
			background-color: transparent;
			background-image: none;

			&:hover {
				color: $color-white;
				background-color: $color-green-2;
				}
		}

		/* Style: Text Only */
		&.btn-text-only {
			color: $color-green-2 !important;
			text-transform: none;
			background-color: transparent;

			&:hover {
				background-color: transparent;
				}
		}

		/* Rounded */
		&.btn-rounded {
			@include rounded(20px);
			}

        /* Size: Small */
        &.btn-size-small {
            padding: 7px 20px 5px;
            }

        /* Size: Medium */
        &.btn-size-medium {
            padding: 16px 20px;
            }

        /* Size: Large */
        &.btn-size-large {
            padding: 20px;
            }

		/* Position: Left */
		&.btn-position-left {
			float: left !important;
			}

		/* Position: Right */
		&.btn-position-right {
			float: right !important;
			}

        /* Icons */
        &.btn-icon {

            /* Icon (Custom): Arrow (Right) */
            &-arrow {
                position: relative;

                /* Horizontal Bar */
                &::before {
                    position: absolute;
                    top: 50%;
                    margin-top: -1px;
                    right: 15px;
                    width: 20px;
                    display: block;
                    height: 2px;
                    content: ' ';
                    background-color: $color-white;

                    @include transition(all 0.20s ease-in-out);
                    }

                /* Chevron */
                &::after {
                    position: absolute;
                    top: 50%;
                    right: -13px;
                    margin-top: -4px;
                    display: block;
                    width: 8px;
                    height: 8px;
                    font-family: $faregular;
                    font-size: 0.10rem;
                    line-height: 8.5px;
                    text-align: center;

                    /* https://fontawesome.com/icons/chevron-right?style=light */
                    content: '\f054';

                    @include opacity(0.00);
                    @include transition(all 0.20s ease-in-out);
                    }

                /* Hover State */
                &:hover {

                    &::before {
                        right: -40px;
                        width: 30px;
                        }

                    &::after {
                        right: -42px;
                        font-size: 0.90rem;

                        @include opacity(1.00);
                        }
                }
            }

            /* Icon: Notes */
            &-notes {
                &::before {
                    padding-right: 8px;
                    display: inline-block;
                    font-weight: normal;
                    font-weight: 300;
                    content: '';
                    }
            }

            /* Icon: Bookmark */
            &-bookmark {
                &::before {
                    padding-right: 8px;
                    display: inline-block;
                    font-family: $fasolid;
                    font-weight: normal;
                    font-weight: 300;

                    /* https://fontawesome.com/icons/bookmark?style=solid */
                    content: '\f02e';
                    }
            }

            /* Icon: Additional Info */
            &-additionalinfo {
                &::before {
                    padding-right: 8px;
                    display: inline-block;
                    font-weight: normal;
                    font-weight: 300;
                    content: '';
                    }
            }

            /* Icon: Return */
            &-return {
                &::before {
                    padding-right: 8px;
                    display: inline-block;
                    font-family: $fasolid;
                    font-weight: normal;
                    font-weight: 300;

                    /* https://fontawesome.com/icons/arrow-to-left?style=solid */
                    content: '\f33e';
                    }
            }

            /* Icon: Key */
            &-key {
                &::before {
                    padding-right: 8px;
                    display: inline-block;
                    font-family: $fasolid;
                    font-weight: normal;
                    font-weight: 300;

                    /* https://fontawesome.com/icons/key?style=solid */
                    content: '\f084';
                    }
            }

            /* Icon: Lock */
            &-lock {
                &::before {
                    padding-right: 8px;
                    display: inline-block;
                    font-family: $fasolid;
                    font-weight: normal;
                    font-weight: 300;

                    /* https://fontawesome.com/icons/lock-alt?style=solid */
                    content: '\f30d';
                    }
            }

            /* Icon: Past */
            &-past {
                &::before {
                    padding-right: 8px;
                    display: inline-block;
                    font-family: $faregular;
                    font-weight: normal;
                    font-weight: 300;

                    /* https://fontawesome.com/icons/clock?style=regular */
                    content: '\f017';
                    }
            }

            /* Icon: Print */
            &-print {
                &::before {
                    padding-right: 8px;
                    display: inline-block;
                    font-family: $fasolid;
                    font-weight: normal;
                    font-weight: 300;

                    /* https://fontawesome.com/icons/print?style=solid */
                    content: '\f02f';
                    }
            }

            /* Icon: Search (left) */
            &.-search-left {
                padding-left: 35px;

                &::before {
                    left: 9px;
                    font-family: $fasolid;

                    /* https://fontawesome.com/icons/search?style=solid */
                    content: '\f002';
                    }
            }

            /* Icon: Search (right) */
            &.-search-right {
                padding-right: 35px;

                &::before {
                    right: 9px;
                    font-family: $fasolid;

                    /* https://fontawesome.com/icons/search?style=solid */
                    content: '\f002';
                    }
            }

            /* Icon: Check (left) */
            &.-check-left {
                padding-left: 35px;

                &::before {
                    left: 9px;
                    font-family: $fasolid;

                    /* https://fontawesome.com/icons/check?style=solid */
                    content: '\f00c';
                    }
            }

            /* Icon: Check (right) */
            &.-check-right {
                padding-right: 35px;

                &::before {
                    right: 9px;
                    font-family: $fasolid;

                    /* https://fontawesome.com/icons/check?style=solid */
                    content: '\f00c';
                    }
            }

            /* Icon: Close (left) */
            &.-close-left {
                padding-left: 35px;

                &::before {
                    left: 12px;
                    font-family: $fasolid;

                    /* https://fontawesome.com/icons/times?style=solid */
                    content: '\f00d';
                    }
            }

            /* Icon: Close (right) */
            &.-close-right {
                padding-right: 35px;

                &::before {
                    right: 12px;
                    font-family: $fasolid;

                    /* https://fontawesome.com/icons/times?style=solid */
                    content: '\f00d';
                    }
            }

            /* Icon: Document (left) */
            &.-document-left {
                padding-left: 35px;

                &::before {
                    left: 9px;
                    font-family: $fasolid;

                    /* https://fontawesome.com/icons/file-alt?style=solid */
                    content: '\f15c';
                    }
            }

            /* Icon: Document (right) */
            &.-document-right {
                padding-right: 35px;

                &::before {
                    right: 9px;
                    font-family: $fasolid;

                    /* https://fontawesome.com/icons/file-alt?style=solid */
                    content: '\f15c';
                    }
            }

            /* Icon: Edit (left) */
            &.-edit-left {
                padding-left: 35px;

                &::before {
                    left: 9px;
                    font-family: $fasolid;

                    /* https://fontawesome.com/icons/edit?style=solid */
                    content: '\f044';
                    }
            }

            /* Icon: Edit (right) */
            &.-edit-right {
                padding-right: 35px;

                &::before {
                    right: 9px;
                    font-family: $fasolid;

                    /* https://fontawesome.com/icons/edit?style=solid */
                    content: '\f044';
                    }
            }

            /* Icon: Email (left) */
            &.-email-left {
                padding-left: 35px;

                &::before {
                    left: 9px;
                    font-family: $fasolid;

                    /* https://fontawesome.com/icons/envelope?style=solid */
                    content: '\f0e0';
                    }
            }

            /* Icon: Email (right) */
            &.-email-right {
                padding-right: 35px;

                &::before {
                    right: 9px;
                    font-family: $fasolid;

                    /* https://fontawesome.com/icons/envelope?style=solid */
                    content: '\f0e0';
                    }
            }

            /* Icon: File (left) */
            &.-file-left {
                padding-left: 35px;

                &::before {
                    left: 9px;
                    font-family: $fasolid;

                    /* https://fontawesome.com/icons/file?style=solid */
                    content: '\f15b';
                    }
            }

            /* Icon: File (right) */
            &.-file-right {
                padding-right: 35px;

                &::before {
                    right: 9px;
                    font-family: $fasolid;

                    /* https://fontawesome.com/icons/file?style=solid */
                    content: '\f15b';
                    }
            }

            /* Icon: Print (left) */
            &.-print-left {
                padding-left: 35px;

                &::before {
                    left: 9px;
                    font-family: $fasolid;

                    /* https://fontawesome.com/icons/print?style=solid */
                    content: '\f02f';
                    }
            }

            /* Icon: Print (right) */
            &.-print-right {
                padding-right: 35px;

                &::before {
                    right: 9px;
                    font-family: $fasolid;

                    /* https://fontawesome.com/icons/print?style=solid */
                    content: '\f02f';
                    }
            }

            /* Icon: Refresh (left) */
            &.-refresh-left {
                padding-left: 35px;

                &::before {
                    left: 9px;
                    font-family: $fasolid;

                    /* https://fontawesome.com/icons/redo-alt?style=solid */
                    content: '\f2f9';
                    }
            }

            /* Icon: Check (right) */
            &.-refresh-right {
                padding-right: 35px;

                &::before {
                    right: 9px;
                    font-family: $fasolid;

                    /* https://fontawesome.com/icons/redo-alt?style=solid */
                    content: '\f2f9';
                    }
            }

            /* Icon: Siginin (left) */
            &.-login-left,
            &.-signin-left {
                padding-left: 35px;

                &::before {
                    left: 9px;
                    font-family: $fasolid;

                    /* https://fontawesome.com/icons/sign-in-alt?style=solid */
                    content: '\f2f6';
                    }
            }

            /* Icon: Signin (right) */
            &.-login-right,
            &.-signin-right {
                padding-right: 35px;

                &::before {
                    right: 9px;
                    font-family: $fasolid;

                    /* https://fontawesome.com/icons/sign-in-alt?style=solid */
                    content: '\f2f6';
                    }
            }

        }

		/* Background Color: Blue (default) */
		&.btn-color-blue {
			color: $color-white !important;
			border-color: $color-blue-1;
			background-color: $color-blue-1;
			}

		/* Background Color: Green */
		&.btn-color-green {
			color: $color-white !important;
			border-color: $color-green-r1;
			background-color: $color-green-r1;

            /* Hover State */
            &:hover {
                background-color: $color-green-1;

                @include opacity(0.80);
                }
        }

		/* Background Color: Red */
		&.btn-color-red {
			color: $color-white !important;
			border-color: $color-red-r1;
			background-color: $color-red-r1;

            /* Hover State */
            &:hover {
                background-color: $color-red-5;

                @include opacity(1.00);
                }
        }

		/* Text Color: Blue */
		&.btn-text-color-blue {
			color: $color-blue-1 !important;

            /* Hover State */
			&:hover {}
		}

		/* Text Color: Green */
		&.btn-text-color-green {
			color: $color-green-2 !important;

            /* Hover State */
			&:hover {}
		}

		/* Text Color: Red */
		&.btn-text-color-red {
			color: $color-red-2 !important;

            /* Hover State */
			&:hover {}
		}

    }

    /* Button Wrapper */
    .button-wrapper {
        padding-top: 25px;

        &::before,&::after {
            clear: both;
            margin: 0;
            padding: 0;
            display: table;
            width: 0;
            height: 0;
            font-size: 0;
            line-height: 0;
            content: ' ';
            overflow: hidden;
            visibility: hidden;
            *zoom: 1;
            }

        .btn,.button {
            display: block;
            float: left;

            &:last-child {
                margin-right: 0;
                }
        }

		/* Position: Center */
		&.btn-position-center {
            text-align: center !important;

            .btn,.button {
                margin: 0 auto !important;
                display: inline-block !important;
                float: none !important;
                }
        }
    }
}
