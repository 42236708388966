/* ************************************************************************************************ */
/* Header ***************************************************************************************** */
/* ************************************************************************************************ */

/* Header Container */
.sec-header {
    background-color: $color-white;

    .container {
        padding-right: 15px;
        padding-left: 15px;

        > .row {
            > .col-12 {}
        }
    }
}

/* Media Queries */
/* 959px (max-width) */
@media screen and (max-width: 959px){

    /* Header Container */
    .sec-header {
        .container {}
    }
}

/* 1200px (min-width) */
@media screen and (min-width: 1200px){

    /* Header Container */
    .sec-header {
        .container {}
    }
}



/* Logo */
.sec-header {
    .container {

        /* Logo */
        /*
        .header-logo {
            position: absolute;
			top: 50%;
			left: 15px;
			z-index: 1;
			margin: -29px 0 0;
			width: 100%;
			max-width: 60px;
			height: auto;

            a {
                display: block;
				width: 100%;
				height: auto;

                img {
					display: block;
					width: 100%;
					height: auto;
					border: 0 none;
					}

                span {
                    &.header-logo-text {
                        position: absolute;
                        top: 50%;
                        left: 80px;
                        margin: -20px 0 0;
                        display: block;
                        width: 415px;
                        color: $color-darkgray-3;
                        font-size: 2.75rem;
                        text-transform: uppercase;
                        }

                    &.header-logo-text-inner-1 {
                        padding-right: 15px;
                        display: block;
                        float: left;
                        font-weight: bold;
                        font-weight: 600;
                        }

                    &.header-logo-text-inner-2 {
                        display: block;
                        float: left;
                        }
                }
            }
        }
        */

        /* Header Logo and Title */
        .header-logo {
            display: block;
            float: left;
            width: auto;
            max-width: none;

            a {
                margin: 10px 0 0;
                display: inline-block;
                color: $color-black;

                #r1_svg_logo {
                    display: block;
                    float: left;
                    width: 100%;
                    max-width: 60px;
                    height: auto;
                    }

                span {
                    &.header-logo-text {
                        margin: 10px 0 0 15px;
                        display: inline-block;
                        width: auto;
                        font-size: 2.25rem;
                        line-height: 2.55rem;
                        text-transform: none;
                        white-space: nowrap;
                        }
                }
            }
        }

    }
}

/* Media Queries */
/* 850px */
@media screen and (max-width: 850px){
    .sec-header {
        .container {

            /* Logo */
            .header-logo {

                a {
                    span {
                        &.header-logo-text {
                            margin-top: 10px;
                            font-size: 1.75rem;
                            }
                    }
                }
            }
        }
    }
}

/* 550px */
@media screen and (max-width: 550px){
    .sec-header {
        .container {

            /* Logo */
            .header-logo {
                a {
                    span {
                        &.header-logo-text {
                            font-size: 1.15rem;
                            }
                    }
                }
            }
        }
    }
}



/* Header Topic & Activity Name */
ul {
    &.topic-activity-name-list {
        position: absolute;
        top: 50%;
        left: 90px;
        margin: 0;
        padding: 0;
        display: block;
        float: left;
        list-style-type: none;

        @include transform(translateY(-50%));

        > li {
            margin: 0;
            padding: 0 15px 0 0;
            display: block;
            float: left;
            color: $color-darkgray-3;
            font-size: 1.35rem;

            &.topic-name {
                position: relative;
                padding-right: 20px;
                font-weight: bold;
                font-weight: 600;

                &::before {
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    margin: -8px 0 0;
                    width: 1px;
                    height: 16px;
                    content: ' ';
                    background-color: $color-darkgray-1;
                    }
            }

            &.activity-name {}

            &:last-child { padding-right: 0; }
        }
    }
}

/* Media Queries */
/* 700px */
@media screen and (max-width: 700px){
    ul {
        &.topic-activity-name-list {
            > li {
                float: none;
                font-size: 1.00rem;
                line-height: 1.35rem;

                &.topic-name {
                    padding-right: 0;

                    &::before { display: none; }
                }
            }
        }
    }
}

/* 550px */
@media screen and (max-width: 550px){
    ul {
        &.topic-activity-name-list {
            left: 70px;
            }
    }
}
