/* ************************************************************************************************ */
/* Error: 404 ************************************************************************************* */
/* ************************************************************************************************ */
#section-main {
	.content-area {
		&.page-404 {
            padding-top: 30px;
            padding-bottom: 30px;

            ul {
                margin-left: 0;
                padding-left: 0;
                list-style-type: none;

                > li {
                    position: relative;
                    margin: 0;
                    padding: 4px 0 4px 25px;

                    &::before {
                        position: absolute;
                        top: 5px;
                        left: 0;
                        display: block;
                        width: 18px;
                        height: 18px;
                        font-family: $faregular;
                        font-size: 0.90rem;

                        /* https://fontawesome.com/icons/chevron-right?s=regular */
                        content: '\f054';
                        line-height: 18px;
                        text-align: center;
                        }
                }
            }
        }
	}
}
