/* ************************************************************************************************ */
/* Colors ***************************************************************************************** */
/* ************************************************************************************************ */

$color-white 	    : #fff;
$color-black        : #000;

$color-lightgray-1	: #f2f2f2;  /* lmi light gray */
$color-lightgray-2	: #eee;	    /* Used with blockquotes icon */
$color-lightgray-3	: #ddd;
$color-lightgray-4	: #ccc;
$color-lightgray-5	: #bbb;
$color-lightgray-6	: #aaa;
$color-lightgray-7  : #f8f8f8;

$color-gray-1		: #888;
$color-gray-2		: #999;

$color-darkgray-1	: #555;
$color-darkgray-2	: #444;	   /* Dark Gray */
$color-darkgray-3	: #333;
$color-darkgray-4	: #222;
$color-darkgray-5	: #1a1a1a; /* Text */

$color-green-1      : #16aa7f; /* Green */
$color-green-2      : #00a651; /* Green */
$color-green-3      : #76bc43; /* R1 Green */
$color-green-4      : #43b02a;
$color-green-5      : #6ebe46;

$color-orange-1     : #fff6ec; /* Orange */
$color-orange-2     : #fa8817; /* Orange */
$color-orange-3     : #f9c012; /* Orange */

$color-red-1		: #ffeaea; /* Red */
$color-red-2		: #ff0000; /* Red */
$color-red-3		: #b10000; /* Red */
$color-red-4		: #b51b46; /* Red */
$color-red-5		: #cc1f36; /* Red */

$color-blue-1       : #cceaff; /* Light Blue */
$color-blue-2		: #009eff; /* Blue */
$color-blue-3		: #0f267f; /* Navy */
$color-blue-4		: #074c7f; /* Navy */

$color-violet-1		: #f0eff6; /* Violet */
$color-violet-2		: #493691; /* Violet */
$color-violet-3		: #1d1b4c; /* Violet */
$color-violet-4		: #2f175c; /* Violet: Buttons, button borders, title partial underlines */
$color-violet-5		: #5c4f8c; /* Violet */

/* WordPress-Specific */
$color-wp-blue-1    : #0073aa;

$color-green-r1     : #43b02a; /* R1 Green */
$color-red-r1       : #e4002b; /* R1 Red */


/* Text Colors */
.text-white         { color: $color-white !important; } /* #fff */
.text-black         { color: $color-black !important; } /* #000 */

.text-lightgray-1   { color: $color-lightgray-1 !important; } /* #f2f2f2 light gray */
.text-lightgray-2   { color: $color-lightgray-2 !important; } /* #eee Used with blockquotes icon */
.text-lightgray-3   { color: $color-lightgray-3 !important; } /* #ddd */
.text-lightgray-4   { color: $color-lightgray-4 !important; } /* #ccc */
.text-lightgray-5   { color: $color-lightgray-5 !important; } /* #bbb */
.text-lightgray-6   { color: $color-lightgray-6 !important; } /* #aaa */

.text-gray-1        { color: $color-gray-1 !important; } /* #888 */
.text-gray-2        { color: $color-gray-2 !important; } /* #999 */

.text-darkgray-1    { color: $color-darkgray-1 !important; } /* #555 */
.text-darkgray-2    { color: $color-darkgray-2 !important; } /* #444 Dark Gray */
.text-darkgray-3    { color: $color-darkgray-3 !important; } /* #333 */
.text-darkgray-4    { color: $color-darkgray-4 !important; } /* #222 */
.text-darkgray-5    { color: $color-darkgray-5 !important; } /* #1a1a1a Text */

.text-green-1       { color: $color-green-1 !important; } /* #16aa7f Green */
.text-green-2       { color: $color-green-2 !important; } /* #00a651 Green */
.text-green-3       { color: $color-green-3 !important; } /* #76bc43 Green */
.text-green-4       { color: $color-green-4 !important; } /* # Green */
.text-green-5       { color: $color-green-5 !important; } /* # Green */

.text-orange-1      { color: $color-orange-1 !important; } /* #fff6ec Orange */
.text-orange-2      { color: $color-orange-2 !important; } /* #fa8817 Orange */
.text-orange-3      { color: $color-orange-3 !important; } /* #f9c012 Orange */

.text-red-1         { color: $color-red-1 !important; } /* #ffeaea Red */
.text-red-2         { color: $color-red-2 !important; } /* #ff0000 Red */
.text-red-3         { color: $color-red-3 !important; } /* #b10000 Red */
.text-red-4         { color: $color-red-4 !important; } /* #b51b46 Red */
.text-red-5         { color: $color-red-4 !important; } /* #b51b46 Red */

.text-blue-1        { color: $color-blue-1 !important; } /* #cceaff Light Blue */
.text-blue-2        { color: $color-blue-2 !important; } /* #009eff Blue */
.text-blue-3        { color: $color-blue-3 !important; } /* #0f267f Navy */
.text-blue-4        { color: $color-blue-4 !important; } /* #074c7f Navy */

.text-violet-1      { color: $color-violet-1 !important; } /* #f0eff6 Violet */
.text-violet-2      { color: $color-violet-2 !important; } /* #493691 Violet */
.text-violet-3      { color: $color-violet-3 !important; } /* #1d1b4c Violet */
.text-violet-4      { color: $color-violet-4 !important; } /* #2f175c Violet */
.text-violet-5      { color: $color-violet-5 !important; } /* #5c4f8c Violet */

.text-wp-blue-1     { color: $color-wp-blue-1 !important; } /* #0073aa */

.text-green-r1      { color: $color-green-r1 !important; } /* #78be20 */
.text-red-r1        { color: $color-red-r1 !important; } /* #e4002b */



/* Background Colors */
.bg-white         { background-color: $color-white !important; } /* #fff */
.bg-black         { background-color: $color-black !important; } /* #000 */

.bg-lightgray-1   { background-color: $color-lightgray-1 !important; } /* #f2f2f2 light gray */
.bg-lightgray-2   { background-color: $color-lightgray-2 !important; } /* #eee Used with blockquotes icon */
.bg-lightgray-3   { background-color: $color-lightgray-3 !important; } /* #ddd */
.bg-lightgray-4   { background-color: $color-lightgray-4 !important; } /* #ccc */
.bg-lightgray-5   { background-color: $color-lightgray-5 !important; } /* #bbb */
.bg-lightgray-6   { background-color: $color-lightgray-6 !important; } /* #aaa */

.bg-gray-1        { background-color: $color-gray-1 !important; } /* #888 */
.bg-gray-2        { background-color: $color-gray-2 !important; } /* #999 */

.bg-darkgray-1    { background-color: $color-darkgray-1 !important; } /* #555 */
.bg-darkgray-2    { background-color: $color-darkgray-2 !important; } /* #444 Dark Gray */
.bg-darkgray-3    { background-color: $color-darkgray-3 !important; } /* #333 */
.bg-darkgray-4    { background-color: $color-darkgray-4 !important; } /* #222 */
.bg-darkgray-5    { background-color: $color-darkgray-5 !important; } /* #1a1a1a Text */

.bg-green-1       { background-color: $color-green-1 !important; } /* #16aa7f Green */
.bg-green-2       { background-color: $color-green-2 !important; } /* #00a651 Green */
.bg-green-3       { background-color: $color-green-3 !important; } /* #76bc43 Green */
.bg-green-4       { background-color: $color-green-4 !important; } /* #6ebe46 Green */
.bg-green-5       { background-color: $color-green-5 !important; } /* #6ebe46 Green */

.bg-orange-1      { background-color: $color-orange-1 !important; } /* #fff6ec Orange */
.bg-orange-2      { background-color: $color-orange-2 !important; } /* #fa8817 Orange */
.bg-orange-3      { background-color: $color-orange-3 !important; } /* #f9c012 Orange */

.bg-red-1         { background-color: $color-red-1 !important; } /* #ffeaea Red */
.bg-red-2         { background-color: $color-red-2 !important; } /* #ff0000 Red */
.bg-red-3         { background-color: $color-red-3 !important; } /* #b10000 Red */
.bg-red-4         { background-color: $color-red-4 !important; } /* #b51b46 Red */
.bg-red-5         { background-color: $color-red-4 !important; } /* #b51b46 Red */

.bg-blue-1        { background-color: $color-blue-1 !important; } /* #cceaff Light Blue */
.bg-blue-2        { background-color: $color-blue-2 !important; } /* #009eff Blue */
.bg-blue-3        { background-color: $color-blue-3 !important; } /* #0f267f Navy */
.bg-blue-4        { background-color: $color-blue-4 !important; } /* #074c7f Navy */

.bg-violet-1      { background-color: $color-violet-1 !important; } /* #f0eff6 Violet */
.bg-violet-2      { background-color: $color-violet-2 !important; } /* #493691 Violet */
.bg-violet-3      { background-color: $color-violet-3 !important; } /* #1d1b4c Violet */
.bg-violet-4      { background-color: $color-violet-4 !important; } /* #2f175c Violet */
.bg-violet-5      { background-color: $color-violet-5 !important; } /* #5c4f8c Violet */

.bg-wp-blue-1     { background-color: $color-wp-blue-1 !important; } /* #0073aa */

.bg-green-r1      { background-color: $color-green-r1 !important; } /* #78be20 */
.bg-red-r1        { background-color: $color-red-r1 !important; } /* #e4002b */
