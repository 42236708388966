/* ************************************************************************************************ */
/* SCSS Variables ********************************************************************************* */
/* ************************************************************************************************ */

/* Margin/Padding Amounts */
$spaceamounts		: (0,5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100);
$spacesides			: (all,top,right,bottom,left);

/* Browser Vendor Prefixes */
$vendors			: "-webkit-", "-moz-", "-ms-", "-o-", "";

/* Font Stacks */
$font1        		: 'Lato', Arial, Helvetica, sans-serif;
$font2        		: 'RobotoSlab', 'Georgia', 'Times New Roman', serif;
$font3        		: Arial, Helvetica, sans-serif;

/* Custom Icons */
$lmi				: 'lmi', $font3;

/* Font Awesome */
$fabrands           : 'Font Awesome Brands', $font3;
$falight            : 'Font Awesome Light', $font3;
$faregular          : 'Font Awesome Regular', $font3;
$fasolid            : 'Font Awesome Solid', $font3;

/* Global Font Size */
$global-font-size   : 16px;

/* Max-Width */
$maxwidth			: 1400px;

/* Assessment Variables */
$grid-gutter-width: 30px;
