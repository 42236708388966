#section-main {
    .sec-main {
        .sec-main-inner {
            .content-area {
                &.homepage {
                    .page-content {
                        padding-top: 30px;
                        padding-bottom: 20px;

                        > .container {

                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}
#section-main {
    .sec-main {
        .sec-main-inner {
            .content-area {
                &.homepage {
                    .r1l-banner-slider {
                        margin: 0 auto;
                        padding-right: 0;
                        padding-left: 0;

                        h1,h2,h3,h4,h5,h6,p {
                            color: $color-darkgray-3;
                            }

                        .r1l-banner-slider-outer {
                            position: relative;
                            margin: 0;
                            padding: 0;
                            width: 100%;
                            height: 100%;
                            overflow: hidden;

                            .r1l-banner-slider-inner {
                                position: relative;
                                z-index: 2;
                                margin: 0;
                                padding: 0;
                                overflow: hidden;

                                .r1l-banner-slide {
                                    position: relative;
                                    height: 100%;
                                    overflow: hidden;

                                    .r1l-banner-slide-outer {
                                        margin: 0 auto;
                                        height: 100%;
                                        background-position: 50% 50%;
                                        background-repeat: no-repeat;
                                        background-size: cover;

                                        .r1l-banner-slide-inner {
                                            position: relative;
                                            z-index: 10;
                                            /* padding: 135px 15px; */
                                            padding: 40px 15px;
                                            height: 100%;

                                            > .container {
                                                margin: 0 auto;
                                                padding-right: 15px;
                                                padding-left: 15px;
                                                max-width: $maxwidth;
                                                height: 100%;

                                                > .row {
                                                    > .col-12 {}
                                                }
                                            }

                                            h2 {
                                                padding: 0 0 15px;
                                                font-family: 'Lato', Arial, Helvetivca, Sans-serif;
                                                }

                                            p {
                                                padding: 0 0 15px;
                                                font-family: 'Lato', Arial, Helvetivca, Sans-serif;
                                                font-size: 1.25rem;
                                                font-weight: 300;
                                                }

                                            .button-wrapper {
                                                padding: 25px 0 0;

                                                a {
                                                    &.button {
                                                        position: relative;
                                                        margin: 0;
                                                        padding: 16px 35px;
                                                        line-height: 1;
                                                        border: 1px solid $color-green-4;
                                                        background-color: $color-green-4;

                                                        @include transition(all 0.35s ease-in-out);
                                                        @include rounded(3px);

                                                        span {
                                                            position: relative;
                                                            z-index: 3;
                                                            color: $color-white;

                                                            @include transition(all 0.25s ease-in-out);
                                                            }

                                                        /* Hover State */
                                                        &:hover {
                                                            color: $color-white;
                                                            border-color: $color-green-5;
                                                            background-color: $color-green-5;
                                                            }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                        }

                        /* Full Width */
                        &.full-width {
                            width: 100%;
                            max-width: 100%;
                            }

                        /* If not Adaptive */
                        &.not-adaptive .slick-track {
                            display: flex;

                            .slick-slide {
                                display: flex;
                                height: auto;
                                align-items: center; //optional
                                justify-content: center; //optional
                                }
                        }

                        /* Arrows */
                        .slick-arrow {
                            position: absolute;
                            top: 50%;
                            z-index: 9999;
                            margin: -30px 0 0;
                            padding: 0;
                            display: block;
                            width: 40px;
                            height: 60px;
                            line-height: 60px;
                            text-align: center;
                            text-indent: 0;
                            overflow: hidden;

                            @include transform(translate(0));

                            &::before {
                                display: block;
                                height: 60px;
                                color: $color-green-2;
                                font-family: $faregular;
                                font-size: 2.00rem;
                                line-height: 60px;

                                @include transition(all 0.10s ease-in-out);
                                }

                            /* Hover State */
                            &:hover {
                                &::before {
                                    color: $color-green-1;
                                    color: rgba($color-green-1,0.80);
                                    }
                            }

                            &.slick-prev {
                                left: 15px !important;

                                &::before {

                                    /* https://fontawesome.com/icons/chevron-left?style=regular */
                                    content: '\f053';
                                    }
                            }

                            &.slick-next {
                                right: 15px !important;

                                &::before {

                                    /* https://fontawesome.com/icons/chevron-right?style=regular */
                                    content: '\f054';
                                    }
                            }
                        }

                        /* Dots */
                        .r1l-banner-slider-dots {
                            position: absolute;
                            bottom: 15px;
                            left: 50%;
                            z-index: 9;
                            margin-left: -655px;
                            width: auto;

                            @include transform(translateX(-50%));

                            ul {
                                &.slick-dots {
                                    position: relative;
                                    bottom: auto;
                                    margin: 0;
                                    padding: 0;
                                    list-style-type: none;
                                    text-align: center;

                                    > li {
                                        position: relative;
                                        margin: 0;
                                        padding: 0;
                                        display: inline-block;
                                        width: auto;
                                        height: auto;
                                        color: $color-white;

                                        @include transition(all 0.10s ease-in-out);

                                        a {
                                            position: relative;
                                            margin: 0 10px;
                                            padding: 0;
                                            display: block;
                                            height: 50px;
                                            color: $color-blue-3;
                                            font-size: 1.10rem;
                                            line-height: 50px;
                                            text-align: center;
                                            text-decoration: none;
                                            border: 0 none;
                                            background-color: transparent;

                                            @include opacity(0.50);
                                            @include transition(all 0.10s ease-in-out);
                                            }

                                        &:hover {
                                            a {
                                                color: $color-green-2;

                                                @include opacity(1.00);
                                                }
                                        }

                                        &.slick-active {
                                            a {
                                                color: $color-green-2;

                                                @include opacity(1.00);
                                                }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Media Queries */
/* 1500px */
@media screen and (max-width: 1550px){
    #section-main {
        .sec-main {
            .sec-main-inner {
                .content-area {
                    &.homepage {
                        .r1l-banner-slider {

                            /* Arrows */
                            .slick-arrow {
                                top: auto;
                                bottom: 12px;
                                margin: 0;
                                background-color: $color-white;
                                background-color: rgba($color-white,0.50);

                                &.slick-prev {
                                    right: 65px !important;
                                    left: auto !important;
                                    }

                                &.slick-next {
                                    right: 15px !important;
                                    }
                            }

                        }
                    }
                }
            }
        }
    }
}

/* 1400px */
@media screen and (max-width: 1550px){
    #section-main {
        .sec-main {
            .sec-main-inner {
                .content-area {
                    &.homepage {
                        .r1l-banner-slider {

                            /* Dots */
                            .r1l-banner-slider-dots {
                                position: absolute;
                                bottom: 15px;
                                left: 15px;
                                z-index: 9;
                                margin-left: 0;
                                width: auto;

                                @include transform(translateX(0));
                                }

                        }
                    }
                }
            }
        }
    }
}

/* 1000px */
@media screen and (max-width: 1000px){
    #section-main {
        .sec-main {
            .sec-main-inner {
                .content-area {
                    &.homepage {
                        .r1l-banner-slider {
                            .r1l-banner-slider-outer {
                                .r1l-banner-slider-inner {
                                    .r1l-banner-slide {
                                        .r1l-banner-slide-outer {
                                            .r1l-banner-slide-inner {
                                                h1, p,
                                                .button-wrapper {}
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* 975px */
@media screen and (max-width: 975px){
    #section-main {
        .sec-main {
            .sec-main-inner {
                .content-area {
                    &.homepage {
                        .r1l-banner-slider {
                            .r1l-banner-slider-outer {
                                .r1l-banner-slider-inner {
                                    .r1l-banner-slide {

                                            /* Slide Content */
                                            .r1l-banner-slide-outer {

                                                .r1l-banner-slide-inner {
                                                    padding: 75px 15px;
                                                    }

                                                h2 {
                                                    font-size: 2.25rem;
                                                    line-height: 2.85rem;
                                                    }
                                            }

                                    }
                                }
                            }

                            /* Arrows */
                            .slick-arrow {
                                background-color: transparent;
                                }

                            /* Dots */
                            .r1l-banner-slider-dots {
                                bottom: 12px;
                                }

                        }
                    }
                }
            }
        }
    }
}

/* 900px */
@media screen and (max-width: 900px){
    #section-main {
        .sec-main {
            .sec-main-inner {
                .content-area {
                    &.homepage {
                        .r1l-banner-slider {
                            .r1l-banner-slider-outer {
                                .r1l-banner-slider-inner {
                                    .r1l-banner-slide {
                                        .r1l-banner-slide-outer {
                                            .r1l-banner-slide-inner {
                                                .col-6 {
                                                    float: none;
                                                    flex: 0 0 100%;
                                                    width: 100%;
                                                    max-width: 100%;
                                                    }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
