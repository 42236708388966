
.video_rating_container {
    .inner {
        .stars {
            padding: 15px 0 0;

            .video_rate_dd {}
            .video_rate {
                display: block;
                float: left;
                font-size: 40px;
                }
            .save_rating_container {
                margin: 0 0 0 10px;
                display: block;
                float: left;

                a {
                    &.save_video_rating {
                        position: relative;
                        margin: 0;
                        padding: 10px 15px 8px;
                        display: inline-block;
                        color: $color-white;
                        line-height: unset;
                        text-decoration: none;
                        text-transform: uppercase;
                        background-color: $color-green-r1;

                        @include rounded(4px);
                        @include transition(all 0.20s ease-in-out);

                        span {
                            &.rating-save-txt {
                                padding-right: 20px;
                                font-weight: bold;
                                font-weight: 500;
                                }
                        }

                        i {
                            position: absolute;
                            top: 50%;
                            right: 10px;
                            font-size: 1.05rem;

                            @include transform(translateY(-50%));
                            }

                        /* Hover State */
                        &:hover {
                            @include opacity(0.70);
                            color: $color-white !important;
                            }
                    }
                }
            }
        }
    }
}
