/* ************************************************************************************************ */
/* My Profile ************************************************************************************* */
/* ************************************************************************************************ */
#section-main {
    .sec-main {

        .sec-main-inner {
            > .container {

                /* Content Areas */
                &.content-area {

                    /* My Profile */
                    &.my-profile-page {

                    }
                }
            }
        }
    }
}
