/* ************************************************************************************************ */
/* Notifications ********************************************************************************** */
/* ************************************************************************************************ */

#section-main {
	
	p {
		&.wppb-error {
			margin: 25px 0;
			padding: 15px 20px;
			border: 0 none;
			background-color: $color-red-1;
			
			@include rounded(2px);
			}
	}
	
	/* Event Calendar Pro Notifications */
	.tribe-events-notices {
		margin: 0;
		padding: 0 0 20px;
		border-width: 0;
		background-color: transparent;
		
		text-shadow: none;
		
		@include rounded(0);
		
		ul {
			margin: 0;
			padding: 0 0 15px;
			list-style-type: none;
			
			> li {
				padding: 15px 20px;
				display: block;
				color: $color-white;
				background-color: $color-blue-2;
				
				@include rounded(2px);
				}
		}
	}
}
