/* ************************************************************************************************ */
/* Elements *************************************************************************************** */
/* ************************************************************************************************ */

/* Scroll to Top */
.scroll-to-top {
    position: fixed;
    bottom: 80px;
    right: 30px;
    z-index: 99999;
    margin: 0;
    padding: 3px !important;
    display: none;
    width: 48px;
    height: 48px;
    background-color: $color-orange-2;
    background-color: rgba($color-orange-2,0.80);

    @include rounded(50%);
    @include transition(
        color 0.10s ease-in-out,
        background-color 0.10s ease-in-out
    );

    a {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        color: $color-white;
        color: rgba($color-white,0.55);
        font-size: 1.50rem;
        line-height: 48px;
        text-align: center;

        @include transition(
            color 0.10s ease-in-out,
            border-color 0.10s ease-in-out,
            background-color 0.10s ease-in-out
        );

        /* Arrow Icon */
        &::before {
            font: 1.85rem $faregular;

            /* https://fontawesome.com/icons/arrow-up?style=regular */
            content: '\f062';
            }
    }

    &:hover {
        background-color: rgba($color-orange-2,1.00);

        a {
            color: rgba($color-white,0.90);
            }
    }
}



/* Media Queries */
/*767px*/
@media screen and (max-width: 767px){
    .scroll-to-top { display: none !important; }
}



/* Terms and Conditions */
.terms-and-conditions {
    padding: 0 0 20px;

    label {
        position: relative;
        padding-left: 25px;
        cursor: pointer;

        input[type="checkbox"] {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;
            margin: -1px 0 0 -1px;
            display: block;
            width: 1px;
            height: 1px;

            @include opacity(0.00001);

            + span {
                display: inline-block;

                @include userselect(none);

                &::before {
                    position: absolute;
                    top: -1px;
                    left: 0;
                    display: block;
                    width: 18px;
                    height: 18px;
                    color: $color-white;
                    font-family: $fasolid;
                    font-size: 0.70rem;
                    line-height: 14px;
                    text-align: center;
                    content: ' ';
                    border: 2px solid $color-darkgray-4;

                    @include rounded(2px);
                    @include transition(
                        border-color 0.10s ease-in-out,
                        background-color 0.10s ease-in-out
                    );
                    }
            }

            &:checked {
                + span {
                    &::before {
                        content: '\f00c';
                        border-color: $color-green-r1;
                        background-color: $color-green-r1;
                        }
                }
            }
        }
    }
}
