/* ************************************************************************************************ */
/* Breadcrumbs ************************************************************************************ */
/* ************************************************************************************************ */
#section-main {
    .sec-main {
		.sec-main-inner {
            .content-area {

                .page-banner-breadcrumbs {
                    margin: 0;
                    padding: 0;
                    float: right;
                    font-size: 0.90rem;
                    text-transform: uppercase;

                    .page-banner-breadcrumbs-inner {}

                    ul,
                    ol {
                        &.breadcrumb {
                            margin: 0;
                            padding: 0;
                            float: right;
                            list-style-type: none;

                            > li {
                                position: relative;
                                margin: 0;
                                padding: 0 30px 0 0;
                                display: block;
                                float: left;
                                color: $color-white;

                                &:last-child { padding-right: 0; }

                                &::before {
                                    position: absolute;
                                    top: 50%;
                                    right: 10px;
                                    margin-top: -5px;
                                    display: block;
                                    width: 10px;
                                    height: 10px;
                                    font-family: $fasolid;
                                    font-size: 0.80rem;
                                    line-height: 10px;
                                    text-align: center;

                                    /* https://fontawesome.com/icons/chevron-right?style=solid */
                                    content: '\f054';
                                    }

                                a {
                                    display: block;
                                    color: $color-white;
                                    text-decoration: none;

                                    /* Hover State */
                                    &:hover {
                                        color: $color-lightgray-3;
                                        }
                                }

                                &:last-child {
                                    &::before { display: none; }
                                }
                            }
                        }
                    }
                }

			}
		}
	}
}

/* Media Queries */
/* 650px */
@media screen and (max-width: 650px){
    #section-main {
        .sec-main {
            .sec-main-inner {
                .content-area {
                    .page-banner-breadcrumbs {
                        position: relative;
                        top: auto;
                        right: auto;
                        margin: 2px 0 0;
                        float: left;
                        }
                }
            }
        }
    }
}
