/* ************************************************************************************************ */
/* Clearfix *************************************************************************************** */
/* ************************************************************************************************ */

/* ************************************************************* */ 
/* i. Clear Classes ******************************************** */
/* ************************************************************* */
.clear:before,
.clear:after,
.clear_left:before,
.clear_left:after,
.clear_right:before,
.clear_right:after {
	margin: 0;
	padding: 0;
	display: table;
	width: 0;
	height: 0;
	content: '';
	font-size: 0;
	line-height: 0;
	overflow: hidden;
	*zoom: 1;
	}
	.clear:after 		{ clear: both; }
	.clear_left:after 	{ clear: left; }
	.clear_right:after 	{ clear: right; }

/* Apply clearfix globally to all divs */
div:after,
div::after {
	clear: both;
	margin: 0;
	padding: 0;
	display: table;
	width: 0;
	height: 0;
	font-size: 0;
	line-height: 0;
	content: ' ';
	visibility: hidden;
	overflow: hidden;
	}