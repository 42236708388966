/* ************************************************************************************************ */
/* User Registration ****************************************************************************** */
/* ************************************************************************************************ */
#section-main {
    .sec-main {

        .sec-main-inner {
            > .container {

                /* Content Areas */
                &.content-area {

                    /* Registration Page */
                    &.registration-page {

                    }
                }
            }
        }
    }
}
