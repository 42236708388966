@-moz-keyframes spin {
	100% {
		-moz-transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes grow {
    from {
        transform: scale(0);
    }
    to {
        opacity: 0;
        transform: scale(1);
    }
}

@keyframes glow {
    50% {
        background: rgba(255,255,255,0.60);
    }
}

@keyframes bounce {
    50% {
        transform: scale(0.98)translateY(-5%);
    }
}

