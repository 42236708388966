﻿/* ************************************************************************************************ */
/* Menus ****************************************************************************************** */
/* ************************************************************************************************ */

/* Primary Navigation: Desktop */
.sec-header {
	.header-navigation-wrapper {

        /* Primary */
		&.primary {
			border-bottom: 1px solid $color-lightgray-1;

			.header-navigation-inner {
				position: relative;

				.header-navigation {
					&.primary-navigation {
						margin: 0;
						padding: 0;
						width: auto;
						float: right;
						}
				}

				ul {
					&.primary-navigation-list {
						margin: 0;
						padding: 0;
						float: right;
						list-style-type: none;

						> li {
							margin: 0;
							padding: 0 0 0 35px;
							display: block;
							float: left;

							> a {
								margin: 0;
								padding: 30px 0;
								display: block;
								color: $color-darkgray-3;
								text-transform: uppercase;
								}

							&:first-child {
								padding-left: 0;
								}

							&:hover,
							&.current {
								> a {
									color: $color-green-2;
								    }
							}

							/* Submenu */
							ul {
								&.submenu {
									> li {}
								}
							}
						}
					}
				}
			}
		}
    }
}



/* Secondary Navigation: Desktop */
.sec-header {
	.header-navigation-wrapper {

        /* Secondary */
		&.secondary {
			border-bottom: 1px solid $color-lightgray-1;

			> .container {
                > .row {
                    > .col-12 {}
                }
            }

            .header-navigation-inner {
                padding-top: 10px;
                padding-bottom: 10px;

                .header-authentication {
                    float: right;

                    ul {
                        &.header-authentication-list {
                            margin: 0;
                            padding: 0;
                            float: right;
                            list-style-type: none;

                            > li {
                                position: relative;
                                padding: 0 0 0 20px;
                                display: block;
                                float: left;

                                &::before {
                                    position: absolute;
                                    top: 50%;
                                    left: 10px;
                                    margin-top: -8px;
                                    display: block;
                                    width: 1px;
                                    height: 14px;
                                    content: ' ';
                                    background-color: $color-lightgray-4;
                                    }

                                &:first-child {
                                    padding-left: 0;

                                    &::before { display: none; }
                                }

                                > a {
                                    display: block;
                                    color: $color-darkgray-3;
                                    }

                                /* Hover State */
                                &:hover {
                                    > a {
                                        color: $color-green-2;
                                        }
                                }
                            }
                        }
                    }
                }
            }
		}
	}
}
